import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import LoaderButton from "../LoaderButton";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { Text } from '../../services/translator';

import {
    getProductDispatch
} from "../../services/products_dispatches";
import {
    saveProductTransfer,
    getProductTransfer,
    updateProductTransfer,
    removeProductTransfer,
    receiveProductTransfer
} from "../../services/products_transfer";
import {
    getVendorName,
    getUserName,
    getCatName
} from "../../services/shared";
import {
    getAllProducts,
    getVendorProducts,
    getAllAvailableVendorProducts
} from "../../services/products";

var moment = require('moment');


export default function ProductTransfer({ onSave, product, productId, status, showForm = true, id = 0, btTitle, btColor }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        vendors,
        vendor,
        user,
        users,
        productQuantityTypes,
        productCategories
    } = useAppContext();

    const [open, setOpen] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [showFormSection, setShowFormSection] = useState(false);
    const [vendorId, setVendorId] = useState(0);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productDispatch, setProductDispatch] = useState({});
    const [formInput, setFormInput] = useState({
        vendorId: '', // Transfer from
        productId: '',
        categoryId: '',
        productDescription: '',
        productDispatchId: '',
        transferTo: '',
        transferProcessor: '',
        blNumber: 'BL-' + Date.now(),
        transferDate: Date.now(),
        productQuantity: 0,
        productQuantityType: '',
        transferReason: '',
        transferStatus: '',
        requestedBy: '',
        acceptedBy: null,
        confirmedBy: null,
        acceptedDate: null,
        confirmedDate: null,
        transferItems: []
    });


    useEffect(async () => {
       
        if (id == 0) {
            let newFormInput = {
                ...formInput,
                ['transferDate']: Date.now(),
                ['requestedBy']: user.userId,
                ['transferTo']: vendor.vendorId,
                ['transferStatus']: 'requested',
            };

            setFormInput(newFormInput);
        } else {
            // let productDispatchRecord = await getProductDispatch(product.productDispatchId);
            //let res = await getProductTransfer(id);
            //console.log('transfer555', res);
            await getSelectedVendorProducts(product.vendorId);

            setVendorId(product.vendorId);
            setFormInput({ ...product });

            //setProductDispatch(productDispatchRecord);
        }

    }, [product, user, vendor, status, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSave = async () => {
        let newFormInput = { ...formInput };
        setIsLoading(true);
        setShowSave(false);
        if (id == 0) {
            let res = await saveProductTransfer({ ...newFormInput });
            actions.onSetFeedback(true, 'Product transfer was successfully created.', 'success');
        } else {
            let res = await updateProductTransfer(id, { ...newFormInput });
            actions.onSetFeedback(true, 'Product transfer was successfully updated.', 'success');
        }

        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    const handleStatusUpdate = async (requestStaus) => {
        let newFormInput = { ...formInput };

        // if (+newFormInput.productQuantity > +productDispatch.productQuantity) {
        //     onError('Not enough products.');
        //     return;
        // }

        setIsLoading(true);
        setShowSave(false);
        let feedB = '';

        if (requestStaus == 'accept') {
            newFormInput.transferStatus = 'accepted';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully accepted.'
        } else if (requestStaus == 'decline') {
            newFormInput.transferStatus = 'declined';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully declined.'
        } else if (requestStaus == 'confirm') {
            newFormInput.transferStatus = 'confirmed';
            newFormInput.confirmedBy = user.userId;
            newFormInput.confirmedDate = Date.now();
            feedB = 'Product transfer was successfully confirmed.'
        }
        let res = await updateProductTransfer(id, { ...newFormInput });

        actions.onSetFeedback(true, feedB, 'success');

        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    const handleCancel = async () => {

        setIsLoading(true);
        setShowSave(false);

        await removeProductTransfer(id);
        actions.onSetFeedback(true, 'Product transfer was successfully cancelled.', 'success');
        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    const handleReceive = async (data) => {

        setIsLoading(true);
        setShowSave(false);

        await receiveProductTransfer(data);
        actions.onSetFeedback(true, 'Product transfer was successfully received.', 'success');
        actions.onGetProductTransfers(vendor.vendorId);
        actions.onGetProducts(vendor.vendorId);
        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    function handleChange(event) {
        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);

    }

    function handleInputChange(event, data) {

        let { name, value } = event.target;
        let newProductTransfer = [...formInput.transferItems];
        const index = newProductTransfer.indexOf(data);
        let row = newProductTransfer[index];

        if (name == 'productQuantity' && +value > +data.availableQuantity) {
            return onError('Quantity not available. Available QTY: ' + data.availableQuantity);
        }

        let newRow = {
            ...row,
            [name]: value
        };

        newProductTransfer[index] = newRow;

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }


    function handleProductChange(data, obj) {
        let newProductTransfer = [...formInput.transferItems];

        const index = newProductTransfer.indexOf(data);

        let row = newProductTransfer[index];

        let newRow = {
            ...row,
            ['productId']: obj.productId,
            ['productDispatchId']: obj.productDispatchId,
            ['categoryId']: obj.categoryId,
            ['productQuantityType']: obj.productQuantityType,
            ['availableQuantity']: obj.quantity,
            ['productDescription']: obj.productDescription,
        };

        newProductTransfer[index] = newRow;

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }

    const handleVendorChange = async (event) => {
        setShowFormSection(false);
        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            ['vendorId']: value,
            ['transferItems']: []
        };

        setFormInput(newFormInput);
        setShowSave(true);
        setVendorId(value);
        await getSelectedVendorProducts(value);

    }

    const getSelectedVendorProducts = async (vendorId) => {
        try {
            let response = [];
            let pro = [];

            response = await getVendorProducts(vendorId);
            //console.log('response', response);
            for (let i = 0; i < response.length; i++) {
                //console.log('response[i]', response[i]);
                let obj = {
                    ...response[i],
                    label: response[i].productDescription || '',
                }
                pro.push(obj);
            }
            
            setProducts(pro);
            setShowFormSection(true);

        } catch (err) {
            console.log("Err: " + err);
        }

    };

    const addRow = async () => {
        let newProductTransfer = [...formInput.transferItems];
        let data = {
            productId: '',
            productDispatchId: '',
            categoryId: '',
            productTransferId: 'unique' + Date.now(),
            blNumber: 'BL-' + Date.now(),
            productQuantity: '',
            availableQuantity: '',
            productQuantityType: '',
            productDescription: '',
            status: 'pending'
        };

        newProductTransfer.push(data);

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
    };

    const handleRemove = async (data) => {

        // if (window.confirm("Are you sure you want to remove this record?") != true) {
        //     return;
        // } 
        let newProductTransfer = [...formInput.transferItems];

        const index = newProductTransfer.indexOf(data);
        if (index > -1) {
            newProductTransfer.splice(index, 1);
        }

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);

    };


    return (
        <div>{showForm === true ?

            <div >
                <Button style={{marginLeft: "15px", marginRight: "15px", marginTop: "10px" }} variant="contained" onClick={handleClickOpen} color={btColor}>
                    {btTitle}
                </Button>

                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {/* {status == 'accepted' ? <Text>Product Transfer</Text> : <Text>Request Product Transfer</Text>} | {status == 'accepted' ? getVendorName(vendors, vendor.vendorId) : getVendorName(vendors, product.vendorId)}
                     */}
                        Request Product
                    </DialogTitle>
                    <DialogContent >

                        {formInput.transferTo == vendor.vendorId &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 &&
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={handleCancel}
                                    >
                                        <Text>Cancel Request</Text>
                                    </LoaderButton>
                                }
                            </Typography>
                        }

                        {status == 'needAcceptance' && product.transferStatus != 'declined' &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 &&
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={() => {
                                            handleStatusUpdate('decline');
                                        }}
                                    >
                                        <Text>DECLINE REQUEST</Text>
                                    </LoaderButton>
                                }
                            </Typography>
                        }

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Vendor</InputLabel>
                                <Select
                                    //variant="standard"
                                    disabled={status == 'needAcceptance' ? true : false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="transferTo"
                                    value={vendorId}
                                    label="Transfer To"
                                    onChange={handleVendorChange}
                                >
                                    {vendors.map((vendor, index) =>
                                        <MenuItem key={'fdkor443' + index} value={vendor.vendorId}>{vendor.vendorName} | {vendor.vendorAddress.city || ''}, {vendor.vendorAddress.state || ''}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: 5 }}>

                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    autoFocus
                                    id="transferDate"
                                    name="transferDate"
                                    label={<Text>Transfer Date</Text>}
                                    type="date"
                                    disabled={status == 'needAcceptance' ? true : false}
                                    fullWidth
                                    //  variant="standard"
                                    defaultValue={formInput.transferDate}
                                    onChange={handleChange}
                                />

                            </Grid>

                            {(status == 'accepted' || status == 'needAcceptance') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        id="blNumber"
                                        name="blNumber"
                                        label={<Text>BL Number</Text>}
                                        fullWidth
                                        //variant="standard"
                                        defaultValue={formInput.blNumber}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            }

                            {(status == 'accepted' || status == 'needAcceptance') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Approver</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="transferProcessor"
                                            value={formInput.transferProcessor}
                                            onChange={handleChange}
                                        >
                                            {vendors.map((vendor, index) =>
                                                vendor.isMainVendor == true && <MenuItem key={'o4444j334' + index} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    disabled={status == 'needAcceptance' ? true : false}
                                    id="transferReason"
                                    name="transferReason"
                                    label={<Text>Transfer Reason</Text>}
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    fullWidth
                                    defaultValue={formInput.transferReason}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Divider />
                                <Divider><b><Text>Products</Text></b></Divider>
                                <Divider />
                            </Grid>
                            {status == 'needAcceptance' ?
                                <>
                                    {formInput.transferItems && formInput.transferItems.map((item, index) =>
                                        <Grid item xs={12} sm={12} md={12} key={'fiie444' + index}>

                                            <Grid container alignItems="center">
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b><Text>Product Description</Text>:</b> {item.productDescription} {" - " + getCatName(productCategories, item.categoryId)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b>QTY:</b> {item.productQuantity} {item.productQuantityType}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider variant="middle" />
                                        </Grid>
                                    )}
                                </> :

                                //{
                                formInput.transferItems && formInput.transferItems.map((item, index) =>
                                    <>
                                        <Grid item xs={12} sm={6} md={6} key={'ProductsL' + index}>
                                            <Autocomplete
                                                disabled={status == 'needAcceptance' ? true : false}
                                                freeSolo
                                                id="combo-box-demo"
                                                options={products}
                                                value={item.productDescription}
                                                //getOptionLabel={option => option.productDescription || ''}
                                                onChange={(_event, obj) => {
                                                    handleProductChange(item, obj);
                                                }}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    helperText={"QTY: " + (+item.availableQuantity - +item.productQuantity) + " " + item.productQuantityType}
                                                    fullWidth
                                                    label="Select product"
                                                />}
                                            />
                                            {/* <Autocomplete
                                                disabled={status == 'needAcceptance' ? true : false}
                                                freeSolo
                                                id="free-solo-2-demo"
                                                disableClearable
                                                onChange={(_event, obj) => {
                                                    handleProductChange(item, obj);
                                                }}
                                                value={'Serigne'}
                                                options={products}
                                                getOptionLabel={option => option.productDescription || ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        //style={{ marginTop: 5 }}
                                                        helperText={"QTY: " + (+item.availableQuantity - +item.productQuantity) + " " + item.productQuantityType}
                                                        fullWidth
                                                        {...params}
                                                        label={"Select product"}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                        }}
                                                    />
                                                )}

                                            /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={5} md={5}>
                                            <TextField
                                                id="productQuantity"
                                                name="productQuantity"
                                                label={<Text>Quantity</Text>}
                                                disabled={status == 'needAcceptance' ? true : false}
                                                fullWidth
                                                //type={'number'}
                                                //variant="standard"
                                                defaultValue={item.productQuantity}
                                                onChange={(e) => {
                                                    handleInputChange(e, item);
                                                }}
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={1} md={1}>
                                            <IconButton
                                                style={{
                                                    marginTop: 10,
                                                    // marginBottom: 10,
                                                }}
                                                disabled={status == 'needAcceptance' ? true : false}
                                                color="error"
                                                aria-label="remove attribute"
                                                component="span"
                                                onClick={() => {
                                                    handleRemove(item);
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Grid>
                                        <Divider />

                                    </>
                                )
                                // }

                            }

                            {status != 'needAcceptance' &&
                                <Grid item xs={12} sm={12} md={12}>
                                    {showFormSection == true && <LoaderButton
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                        //disabled={showSave ? false : true}
                                        disabled={(status == 'needAcceptance' && !showSave) ? true : false}
                                        // block
                                        type="submit"
                                        size="sm"
                                        variant="primary"
                                        //  isLoading={isLoading}
                                        onClick={() => {
                                            addRow();
                                        }}
                                    //disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                                    >
                                        {'NEW ROW'}
                                    </LoaderButton>}
                                </Grid>
                            }


                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>
                        {/* <LoaderButton
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isLoading}
                            // disabled={
                            //     showSave &&
                            //         //(formInput.transferTo && formInput.transferTo.length) > 0 &&
                            //        /// (formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                            //         //(formInput.productQuantity && formInput.productQuantity.length > 0) &&
                            //         (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                            onClick={handleSave}
                        >
                            <Text>Save</Text>
                        </LoaderButton> */}

                        {(status == 'needAcceptance') ?
                            <LoaderButton
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                disabled={
                                    showSave &&
                                        (formInput.transferTo && formInput.transferTo.length > 0) &&
                                        (formInput.transferProcessor && formInput.transferProcessor.length > 0) &&
                                        (formInput.blNumber && formInput.blNumber.length > 0) &&
                                        //(formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                                        //(formInput.productQuantity && formInput.productQuantity.length > 0) &&
                                        (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                                onClick={() => {
                                    handleStatusUpdate('accept');
                                }}
                            >
                                {formInput.transferStatus == 'accepted' ? <Text>Save</Text> : 'ACCEPT REQUEST'}
                            </LoaderButton>
                            :
                            //(product.transferStatus != 'declined' && product.transferStatus != 'accepted') ?
                            <LoaderButton
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                disabled={
                                    showSave &&
                                        (formInput.transferTo && formInput.transferTo.length) > 0 &&
                                        //(formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                                        //(formInput.productQuantity && formInput.productQuantity.length > 0) &&
                                        (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                                onClick={handleSave}
                            >
                                <Text>Save</Text>
                            </LoaderButton>
                            //: null
                        }

                    </DialogActions>
                </Dialog>
            </div>
            :
            <div>
                <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 15 }}>
                    {btTitle}
                </Button>
                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={open}
                    onClose={handleClose}
                >

                    <DialogTitle>
                        {'Product Transfer'} {' | '}
                        {product.transferStatus == 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                            product.transferStatus == 'confirmed' ? <Chip label={<Text>confirmed</Text>} color="success" size="small" /> :
                                product.transferStatus == 'requested' ? <Chip label={<Text>requested</Text>} size="small" /> :
                                    product.transferStatus == 'accepted' ? <Chip label={<Text>accepted</Text>} color="primary" size="small" /> :
                                        null

                        }

                    </DialogTitle>

                    <Divider variant="middle" />
                    <DialogContent>
                        <Grid container spacing={3} >
                            <Box sx={{ width: '100%', minWidth: 460, bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>BL Number</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {product.blNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred From</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.vendorId)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred To</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.transferTo)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(product.transferDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">

                                                {getUserName(users, product.requestedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(product.acceptedDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getUserName(users, product.acceptedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Approver</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.transferProcessor)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Typography color="text.secondary" variant="body2">
                                        {product.transferReason}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Divider />
                                        <Divider><b><Text>Products</Text></b></Divider>
                                        <Divider />
                                    </Grid>


                                    {formInput.transferItems && formInput.transferItems.map((item, index) =>
                                        <Grid item xs={12} sm={12} md={12} key={'lkdcdk' + index}>

                                            <Grid container alignItems="center">
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b><Text>Product Description</Text>:</b> {item.productDescription} {" - " + getCatName(productCategories, item.categoryId)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b>QTY:</b> {item.productQuantity} {item.productQuantityType}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider variant="middle" />
                                        </Grid>
                                    )}

                                </Box>
                                {/* <Divider variant="middle" /> */}

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        {(product.transferTo == vendor.vendorId && product.transferStatus != 'accepted' && product.transferStatus != 'confirmed') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="danger"
                                isLoading={isLoading}
                                onClick={handleCancel}
                            >
                                {status !== 'needRemove' ?
                                    <Text>Cancel Request</Text> :
                                    <Text>confirm</Text>
                                }


                            </LoaderButton>
                        }
                        {(status == 'needAcceptance') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleStatusUpdate('confirm');
                                }}
                            >
                                {<Text>confirm</Text>}
                            </LoaderButton>
                        }

                        {(status == 'needReceive') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleReceive(product);
                                }}
                            >
                                {<Text>Receive</Text>}
                            </LoaderButton>
                        }

                    </DialogActions>
                </Dialog>
            </div>
        }


        </div>
    );
}
