import { API } from "aws-amplify";

/**
* Function for getting all Suppliers 
* @param {function} getAllSuppliers
*/
export const getAllSuppliers = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/suppliers");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a Supplier
* @param {function} getSupplier
*/
export const getSupplier = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/suppliers/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a Supplier
* @param {function} updateSupplier
*/
export const updateSupplier = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/suppliers/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a Supplier
* @param {object} data  
* @param {function} saveSupplier
*/
export const saveSupplier = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/suppliers", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove Supplier
* @param {function} removeSupplier
*/
export const removeSupplier = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/suppliers/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};
