import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import NewPass from "../../containers/users/NewPass";
import "./Login.css";

export default function Login() {
    const history = useHistory();
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState('login');
    const [user, setUser] = useState({});
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmationCode: "",
    });

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    function validateForgotPassForm() {
        return fields.email.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            let user = await Auth.signIn(fields.email, fields.password);

            console.log('user', user);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                setUser(user);
                setShowForm('new_pass');

                // history.push({
                //     pathname: '/new-password',
                //     state: {
                //         email: fields.email,
                //         user: user
                //     }
                // });

            } else {
                
                userHasAuthenticated(true);

                window.location.reload(false);
                
            }

            //history.push("/");
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {

                resendConfirmationCode();
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === 'PasswordResetRequiredException') {
                history.push({
                    pathname: '/a_portal/forgot-password',
                    state: { email: fields.email }
                });
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === 'NotAuthorizedException') {
                alert('You are not authorized to use the system');
                // The error happens when the incorrect password is provided
            } else if (err.code === 'UserNotFoundException') {
                alert('User not found.');
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(err);
                onError(err);
            }

            setIsLoading(false);
        }
    }

    async function handleForgotPassSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            // Send confirmation code to user's email
            await Auth.forgotPassword(fields.email);

            history.push({
                pathname: '/a_portal/forgot-password',
                state: { email: fields.email }
            });

        } catch (err) {
            alert(err);
            setIsLoading(false);
        }
    }

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(fields.email);

            history.push({
                pathname: '/a_portal/confirm-account',
                state: { email: fields.email }
            });

        } catch (err) {
            console.log('error resending code: ', err);
        }
    }


    return (
        <div className="Login">
            {showForm == 'login' ?
                <Form onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={fields.password}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button
                            block
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                                //alert('ucchkhxmhxh');
                                setShowForm('');
                            }}
                        >
                            Fogot Password
                        </Button>
                        <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Login
                        </LoaderButton>

                    </div>

                </Form>

                : showForm == 'new_pass' ?

                    <NewPass
                        user={user}
                        email={fields.email}
                    />

                    : <Form onSubmit={handleForgotPassSubmit}>
                        <Form.Group size="lg" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={fields.email}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>

                        <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForgotPassForm()}
                        >
                            Next
                        </LoaderButton>
                    </Form>

            }

        </div>
    );
}