import { API } from "aws-amplify";

var moment = require('moment');


/**
* Function for getting all product received 
* @param {function} getAllProductsReceived
*/
export const getAllProductsReceived = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/products_received");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove product received
* @param {function} removeProductReceived
*/
export const removeProductReceived = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_received/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for getting a product received
* @param {function} getProductReceived
*/
export const getProductReceived = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_received/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product received history
* @param {function} getProductReceivedHistory
*/
export const getProductReceivedHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/products_received/${id}`);

    } catch (err) {
        console.log('error', err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a product received
* @param {object} data  
* @param {function} saveProductReceived 
*/
export const saveProductReceived = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_received", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for updating a product received
* @param {function} updateProductReceived 
*/
export const updateProductReceived = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_received/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



