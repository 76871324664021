import { API } from "aws-amplify";

/**
* Function for getting all vendors 
* @param {function} getAllVendors 
*/
export const getAllVendors = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/vendors");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a vendor 
* @param {function} getVendor 
*/
export const getVendor = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/vendors/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a vendor 
* @param {function} updateVendor 
*/
export const updateVendor = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/vendors/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a vendor
* @param {object} data  
* @param {function} saveVendor 
*/
export const saveVendor = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/vendors", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};
