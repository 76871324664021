import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import LoaderButton from "../../../components/LoaderButton";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InputMask from "react-input-mask";
import Switch from '@mui/material/Switch';

import { countries } from '../../../lib/countries';
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import { Text } from '../../../services/translator';
import {
    saveSupplier,
    updateSupplier
} from "../../../services/suppliers";

var moment = require('moment');


export default function ProductTransfer({ id = 0, btTitle, btColor, supplier = null, viewOnly = false }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        vendors,
        vendor,
        user,
        users,
        productQuantityTypes,
        userPermissions,
        states
    } = useAppContext();

    const [open, setOpen] = useState(false);
    const [showSave, setShowSave] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [productDispatch, setProductDispatch] = useState({});
    const [formInput, setFormInput] = useState({
        supplierId: '', // Transfer from
        supplierName: '',
        email: '',
        phoneNumber: '',
        note: '',
        active: true,
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        },
        shippingAddresses: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        }
    });

    const [errors, setErrors] = useState({
        supplierName: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        city: '',
        state: ''
    });

    const [supplierAddress, setSupplierAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });


    useEffect(async () => {
        // console.log('product',product);
        if (id == 0) {

        } else {
            setFormInput(supplier);
            // let productDispatchRecord = await getProductDispatch(formInput.productDispatchId);
            // let res = await getProductTransfer(id);
            // setFormInput({ ...res });
            // setProductDispatch(productDispatchRecord);
        }

    }, [supplier, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSave = async () => {
        let newFormInput = { ...formInput };

        if (id == 0) {
            setIsLoading(true);
            setShowSave(false);

            let res = await saveSupplier({ ...newFormInput });

            actions.onSetFeedback(true, 'Supplier was successfully created.', 'success');

        } else {

            if (+newFormInput.productQuantity > +productDispatch.productQuantity) {
                onError('Not enough products.');
                return;
            }

            setIsLoading(true);
            setShowSave(false);
            let res = await updateSupplier(id, { ...newFormInput });

            actions.onSetFeedback(true, 'Product transfer was successfully updated.', 'success');

        }
        setFormInput({
            supplierId: '',
            supplierName: '',
            email: '',
            phoneNumber: '',
            note: '',
            active: true,
            address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'Dakar',
                zipCode: '',
                country: 'SN'
            },
            shippingAddresses: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'Dakar',
                zipCode: '',
                country: 'SN'
            }
        });
        actions.onGetSuppliers();

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }

    function handleAddressChange(event) {

        let { name, value } = event.target;
        let newAddress = { ...formInput.address };

        let newAddressInput = {
            ...newAddress,
            [name]: value
        };

        setFormInput({
            ...formInput,
            ['address']: newAddressInput
        });
        setShowSave(true);
    }

    return (
        <div>{viewOnly === false ?

            <div>
                <Button variant="outlined" onClick={handleClickOpen} color={btColor}>
                    {btTitle}
                </Button>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {'Supplier Info'}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container spacing={1}>

                            <Grid container item spacing={3}>

                                <Grid item xs={12}>

                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography><Text>Active</Text></Typography>
                                        <Switch
                                            checked={formInput.active}
                                            onChange={(e) => {
                                                let newFormInput = {
                                                    ...formInput,
                                                    ['active']: e.target.checked
                                                };

                                                setFormInput(newFormInput);
                                                setShowSave(true);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}

                                        />
                                    </Stack>

                                </Grid>

                            </Grid>

                            <Grid container item spacing={3}>

                                <Grid item xs={12} sm={12} md={12}>

                                    <TextField
                                        error={errors.supplierName !== '' ? true : false}
                                        id="outlined-error"
                                        name="supplierName"
                                        helperText={errors.supplierName}
                                        label={"Supplier Name"}
                                        defaultValue={formInput.supplierName}
                                        value={formInput.supplierName}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />

                                </Grid>


                            </Grid>

                            <Grid container item spacing={3}>
                                <Grid item xs={6}>

                                    <TextField
                                        error={errors.email !== '' ? true : false}
                                        id="outlined-error"
                                        name="email"
                                        label={<Text>Email</Text>}
                                        defaultValue={formInput.email}
                                        value={formInput.email}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6}>
                                    <InputMask
                                        mask="99 999 99 99"
                                        value={formInput.phoneNumber}
                                        disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                        maskChar=" "
                                        onChange={handleInputChange}
                                    >
                                        {() =>
                                            <TextField
                                                error={errors.phoneNumber !== '' ? true : false}
                                                id="outlined-error"
                                                name="phoneNumber"
                                                label={<Text>Phone Number</Text>}
                                                fullWidth
                                            />}
                                    </InputMask>
                                </Grid>

                            </Grid>

                            {/* <Divider variant="middle" /> */}
                            <Typography gutterBottom variant="body1">
                                Address
                            </Typography>
                            <Grid container item spacing={3}>

                                <Grid item xs={6}>
                                    <TextField
                                        error={errors.addressLine1 !== '' ? true : false}
                                        id="outlined-error"
                                        name="addressLine1"
                                        label={<Text>Address Line 1</Text>}
                                        defaultValue={formInput.address.addressLine1}
                                        fullWidth
                                        onChange={handleAddressChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-error"
                                        name="addressLine2"
                                        label={<Text>Address Line 2</Text>}
                                        defaultValue={formInput.address.addressLine2}
                                        fullWidth
                                        onChange={handleAddressChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={3}>

                                <Grid item xs={6}>
                                    <TextField
                                        error={errors.city !== '' ? true : false}
                                        id="outlined-error"
                                        name="city"
                                        label={<Text>City</Text>}
                                        defaultValue={formInput.address.city}
                                        fullWidth
                                        onChange={handleAddressChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <FormControl fullWidth>
                                        <TextField
                                            error={errors.state !== '' ? true : false}
                                            id="outlined-error"
                                            name="state"
                                            label={<Text>State</Text>}
                                            defaultValue={formInput.address.state}
                                            fullWidth
                                            onChange={handleAddressChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="countrty"
                                            value={formInput.address.countrty}
                                            label={'Country'}
                                            onChange={(e) => {
                                                //console.log('Event', e);
                                                handleAddressChange(e);
                                            }}
                                        >
                                            {countries.map((s, index) =>
                                                <MenuItem key={index} value={s}>{s}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-error"
                                        name="zipCode"
                                        label={<Text>Zip Code</Text>}
                                        defaultValue={formInput.address.zipCode}
                                        fullWidth
                                        onChange={handleAddressChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="note"
                                        name="note"
                                        label={"Note About Supplier"}
                                        multiline
                                        maxRows={4}
                                        minRows={2}
                                        fullWidth
                                        defaultValue={formInput.note}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        <LoaderButton
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isLoading}
                            disabled={showSave && formInput.supplierName.length > 0 ? false : true}
                            onClick={handleSave}
                        >
                            <Text>Save</Text>
                        </LoaderButton>

                    </DialogActions>
                </Dialog>
            </div>
            :
            <div>
                <Button variant="outlined" onClick={handleClickOpen} color={btColor}>
                    {btTitle}
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {'Supplier Info'}
                    </DialogTitle>

                    <Divider variant="middle" />
                    <DialogContent>
                        <Grid container spacing={3} >
                            <Box sx={{ width: '100%', maxWidth: 560, minWidth: 460, bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                Supplier Name:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {supplier.supplierName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                Supplier Phone:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {supplier.phoneNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                Supplier Email:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {supplier.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="middle" />

                                    <Typography color="text.secondary" variant="body2">
                                        Note: {supplier.note}
                                    </Typography>
                                </Box>
                                <Divider variant="middle" />

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                    </DialogActions>
                </Dialog>
            </div>
        }

        </div>
    );
}
