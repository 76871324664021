import { API } from "aws-amplify";

/**
* Function for getting a approval
* @param {function} getApproval
*/
export const getApproval = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/approvals/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all approvals 
* @param {function} getAllApprovals 
*/
export const getAllApprovals = async () => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/approvals");
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all vendor approvals 
* @param {function} getAllVendorApprovals 
*/
export const getAllVendorApprovals = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/approvals/vendors/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a approval
* @param {object} data  
* @param {function} saveApproval 
*/
export const saveApproval = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/approvals", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a approval 
* @param {function} updateApproval 
*/
export const updateApproval = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/approvals/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for removing an approval 
* @param {function} removeApproval 
*/
export const removeApproval = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/approvals/${id}`);

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting pending dispatch for vendors
* @param {function} getVendorPendingDispatches
*/
export const getVendorPendingDispatches = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/approvals/vendors/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};