import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useHistory, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import { LinkContainer } from "react-router-bootstrap";
import {
    priceFormat
} from "../../services/shared";
import { Text } from '../../services/translator';
import ProductCats from "../../containers/product_categories/ProductCats";
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Colors } from '../../lib/colors';
import ProductTransfer from "../dialogs/ProductTransfer";
import ProductDetailsDialog from "../../containers/products/dialogs/ProductDetailsDialog";
import ProductCategorySelectFilter from '../../containers/products/filters/ProductCategoriesSelectFilter';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'productDescription',
        numeric: false,
        disablePadding: true,
        label: <Text>Product Description</Text>,
    },
    {
        id: 'productID',
        numeric: false,
        disablePadding: false,
        label: <Text>Product Category</Text>,
    },
    {
        id: 'availableQuantity',
        numeric: false,
        disablePadding: false,
        label: <Text>Available Quantity</Text>,
    },
    {
        id: 'productQuantity',
        numeric: false,
        disablePadding: false,
        label: <Text>All Quantity</Text>,
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: <Text>Price</Text>,
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: <Text>Actions</Text>,
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: Colors.darkBlue }}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all products',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            style={{ color: Colors.gold }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, onSearch, products, title, showCategory } = props;
    const history = useHistory();
    const [showManageCat, setShowManageCat] = React.useState(false);

    return (
        <>
            {showCategory == true && <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    <Button
                        variant="contained"
                        endIcon={<SettingsApplicationsIcon />}
                        onClick={() => {
                            setShowManageCat(true);
                        }}
                    >
                        <Text>Manage Categories</Text>
                    </Button>
                </Typography>
            </Toolbar>}
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} <Text>selected</Text>
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {title}
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    // <Tooltip title="Filter list">
                    //     <IconButton>
                    //         <FilterListIcon />
                    //     </IconButton>
                    // </Tooltip>
                    <>
                        <TableFilter
                            onChange={onSearch}
                            type={'products'}
                            data={products}
                        />

                        <ProductCategorySelectFilter
                            type={"products"}
                            onChange={onSearch}
                            data={products}

                        />
                    </>
                )}
            </Toolbar>
            {showManageCat && <ProductCats
                onClose={() => {
                    setShowManageCat(false);
                }}
            />}
        </>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ productsData, pageTitle, showCategory, isVendor = false }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories,
        vendor
    } = useAppContext();
    const [products, setProducts] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('productId');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = products.map((n) => n.productId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    function getCatName(categoryId) {
        let name = '';
        if (productCategories && categoryId) {
            productCategories
                .filter(item => item.categoryId == categoryId)
                .map(item => {
                    name = item.categoryName;
                });
        }
        return name;
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    onSearch={(data) => {
                        setProducts(data);
                    }}
                    products={productsData}
                    title={pageTitle}
                    showCategory={showCategory}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={products.length}
                            onFilter={(data) => {
                                setProducts(data);
                            }}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(products, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.productId);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.productId + row.createdAt}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.productId)}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.productDescription}
                                            </TableCell>
                                            <TableCell align="left">{getCatName(row.categoryId)}</TableCell>
                                            <TableCell align="left">{row.availableQuantity || 'N/A'} {row.availableQuantity != undefined ? row.productQuantityType : ''}</TableCell>
                                            <TableCell align="left">{row.productQuantity || row.quantity} {row.productQuantityType}</TableCell>
                                            <TableCell align="left">{priceFormat(row.productPrice)}</TableCell>

                                            <TableCell align="center" >

                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <ProductDetailsDialog
                                                        quantity={row.productQuantity || row.quantity}
                                                        id={row.productId}
                                                        isVendor={isVendor}
                                                    />

                                                    {isVendor !== true &&
                                                        <Button
                                                            variant="outlined"
                                                            //endIcon={<SendIcon />}
                                                            endIcon={<SettingsApplicationsIcon />}
                                                            onClick={() => {
                                                                history.push(`/a_portal/products/${row.productId}`);
                                                            }}>
                                                            <Text>Edit</Text>
                                                        </Button>
                                                    }
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={products.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}
