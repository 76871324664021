import { API } from "aws-amplify";
import {
    priceFormat,
    getVendorName,
    getSpendingCatName,
    getBankAccountName,
    getUserName
} from "./shared";


var moment = require('moment'); // require
/**
* Function for getting all orders 
* @param {function} getAllOrders
*/
export const runReport = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/reports/excel");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for formatting cashier data 
* @param {function} cashierReportDataFormat
*/
export const cashierReportDataFormat = async (data, cashierCounts, deposits, spendings, vendors, spendingCategories, bankAccounts, users) => {
//Questions To ask
// 1. Where Taxes should be added.
// 2. Should Vehicle expenses be added to Expenses.
// 3. Should we include Cancelled order in Cashier.

    let reportDates = [];
    let loanTotals = [];
    let loanTotal = 0.00;
    let response = [];
    let orderDates = [];
    let newSpendings = [...spendings];
    let newDeposits = [...deposits];
    let total = 0.00;//Without delivery cost
    let totalSpendings = 0.00;//Without delivery cost
    let totalCredits = 0.00;
    let totalDeposits = 0.00; //versement
    let totalVehicleSpendings = 0.00; //versement
    

    try {

        //Add Deposit Dates
        if (newDeposits.length > 0) {
            newDeposits.filter(item => !reportDates.includes(moment(item.depositedDate).format('L')) && item.depositStatus == 'a')
                .map(item => {
                    reportDates.push(moment(item.depositedDate).format('L'));
                });
        }

        //Add Order Dates
        if (data.length > 0) {
            data.filter(item => !reportDates.includes(moment(item.createdDate).format('L')))
                .map(item => {
                    reportDates.push(moment(item.createdDate).format('L'));
                });
        }

        //Add Spending Dates
        if (newSpendings.length > 0) {
            newSpendings.filter(item => !reportDates.includes(moment(item.spendingDate).format('L')))
                .map(item => {
                    reportDates.push(moment(item.spendingDate).format('L'));
                });
        }

        //Make sure there is no duplicated DATES
        reportDates = reportDates.filter(function(item, pos) {
            return reportDates.indexOf(item) == pos;
        });

        //sort Dates
        reportDates = reportDates.sort(function (a, b) {
            return new Date(a) - new Date(b);
        });

        
        //Start loop
        reportDates.forEach((date, i) => {
            //Orders
            data.filter(element => moment(element.createdDate).format('L') == date)
                .map(element => {
                    //total += +element.taxIncludedTotal;
                    let orderDate = moment(element.createdDate).format('L');
                    //Order By Date
                    if (orderDates.includes(orderDate)) {
                        let obj = {
                            'Order Number': element.orderNumber,
                            'Customer': element.fullName,
                            'Grand Total': priceFormat(element.grandTotal)
                        }
                        response.push(obj);
                    } else {

                        let obj = {
                            'Order Date': moment(element.createdDate).format('L'),
                            'Order Number': element.orderNumber,
                            'Customer': element.fullName,
                            'Grand Total': priceFormat(element.grandTotal),
                        }
                        orderDates.push(orderDate);
                        response.push(obj);
                    }

                    //Add Order Items
                    element.orderItems.forEach((element, i) => {
                        total += +element.total;
                        let obj = {
                            'Description': element.product.productDescription,
                            'Quantity': element.quantity,
                            'Price': priceFormat(element.price),
                            'Total': priceFormat(element.total)
                        }
                        response.push(obj);
                    });

                    //Add Credit For Order
                    if (element.orderCredit != null && element.orderCredit > 0) {
                        totalCredits += +element.orderCredit;
                        response.push({
                            'Order Number': element.orderNumber,
                            'Customer': element.fullName,
                            'Description': 'Crédits',
                            'Crédits': priceFormat(element.orderCredit),
                        });
                    }

                    //Add Vehicle income.
                    if (element.shippingPrice > 0) {
                        totalVehicleSpendings += +element.shippingPrice;
                        response.push({
                            'Order Number': element.orderNumber,
                            'Customer': element.fullName,
                            'Description': 'VERSEMEMT CHAUFFEUR',
                            'Vehicle': priceFormat(element.shippingPrice),
                        });
                    }

                    //Add Spendings of a specific order
                    newSpendings.filter(item => item.orderNumber == element.orderNumber)
                        .map(item => {
                            let index = newSpendings.indexOf(item);
                            totalSpendings += +item.spendingAmount;
                            response.push({
                                'Order Number': item.orderNumber,
                                'Customer': element.fullName,
                                'Description': item.spendingCategoryId == null ? item.spendingNote : getSpendingCatName(spendingCategories, item.spendingCategoryId) + ' / ' + item.spendingNote,
                                'Spending': priceFormat(item.spendingAmount)
                            });

                            if (index > -1) { // only splice array when item is found
                                newSpendings.splice(index, 1); // 2nd parameter means remove one item only
                            }
                        });
                });
            //End Orders

            //Add Spendings without Orders
            newSpendings.filter(item => moment(item.spendingDate).format('L') == date)
                .map(item => {
                    let index = newSpendings.indexOf(item);

                    if (item.spendingType == 'loan') {
                        totalSpendings += +item.spendingAmount;
                        response.push({
                            'Order Date': date,
                            'Customer': item.spendingType + ' - ' + getVendorName(vendors, item.vendorId),
                            'Description': item.spendingCategoryId == null ? item.spendingNote : getSpendingCatName(spendingCategories, item.spendingCategoryId) + ' / ' + item.spendingNote,
                            'Spending': priceFormat(item.spendingAmount),
                            'Loan For': getUserName(users, item.loanFor),
                        });

                        let ownerFound = loanTotals.filter(row => row.loanFor === item.loanFor)
                            .map(row => {
                                let index = loanTotals.indexOf(row);

                                if (index > -1) { 
                                    loanTotals[index].total += +item.spendingAmount;
                                }
                            });

                        if (ownerFound.length === 0) {
                            let data = {
                                loanFor: item.loanFor,
                                total: +item.spendingAmount
                            };

                            loanTotals.push(data);
                        }
                        //Add individual totals

                    } else if (item.spendingType == 'vehicle') {
                        totalVehicleSpendings -= +item.spendingAmount;

                        response.push({
                            'Order Date': date,
                            'Customer': getVendorName(vendors, item.vendorId),
                            'Description': item.spendingCategoryId == null ? item.spendingNote : getSpendingCatName(spendingCategories, item.spendingCategoryId) + ' / ' + item.spendingNote,
                            'Vehicle': priceFormat('-'+ item.spendingAmount),
                        });
                    }else {
                        totalSpendings += +item.spendingAmount;
                        response.push({
                            'Order Date': date,
                            'Order Number': item.orderNumber == null? '': item.orderNumber,
                            'Customer': getVendorName(vendors, item.vendorId),
                            'Description': item.spendingCategoryId == null ? item.spendingNote : getSpendingCatName(spendingCategories, item.spendingCategoryId) + ' / ' + item.spendingNote,
                            'Spending': priceFormat(item.spendingAmount),
                        });
                    }

                    if (index > -1) { // only splice array when item is found
                        newSpendings.splice(index, 1); // 2nd parameter means remove one item only
                    }
                });

            //Add Deposits
            newDeposits.filter(item => moment(item.depositedDate).format('L') == date && item.depositStatus == 'a')
                .map(item => {
                    let index = newDeposits.indexOf(item);
                    totalDeposits += +item.depositedAmount;
                    response.push({
                        'Order Date': date,
                        'Customer': getVendorName(vendors, item.vendorId),
                        'Description': item.accountId == null ? item.spendingNote : item.spendingNote != null ? getBankAccountName(bankAccounts, item.accountId) + ' / ' + item.spendingNote : getBankAccountName(bankAccounts, item.accountId),
                        'Versement': priceFormat(item.depositedAmount)
                    });
                    if (index > -1) { // only splice array when item is found
                        newDeposits.splice(index, 1); // 2nd parameter means remove one item only
                    }
                });
        });

        //Add Empty Row
        response.push({});
        response.push({});

        loanTotals.forEach((sp, i) => {
            loanTotal += sp.total;
            response.push({
                'Loan For': getUserName(users, sp.loanFor) + ' - Total',
                'Loan Totals': priceFormat(sp.total),
            });
        });
         //Add Empty Row
         response.push({});

        //Add Total Row
        response.push({
            'Total': priceFormat(total),
            'Spending': priceFormat(totalSpendings),
            'Crédits': priceFormat(totalCredits),
            'Vehicle': priceFormat(totalVehicleSpendings), 
            'Versement': priceFormat(totalDeposits),//versement
            'Loan Totals': priceFormat(loanTotal),
        });

        let totalAmount = 0.00;
        totalAmount = total - totalSpendings - totalCredits + totalVehicleSpendings - totalDeposits;
        //totalAmount = total - totalSpendings - totalCredits - totalDeposits;

        //Add Total Row
        response.push({
            'Total': priceFormat(totalAmount)
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};