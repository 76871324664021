import React, { useState, useEffect, useContext } from "react";
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAppContext } from "../../lib/contextLib";
import {
    getVendorCashierData
} from "../../services/orders";
import {
    runReport
} from "../../services/reports";
import SpendingsDialog from "./dialogs/SpendingsDialog";
import { Text } from '../../services/translator';
import CashierTable from "./tables/CashierTable";
import ExpensesTable from "./tables/ExpensesTable";
import CustomerCreditsTable from "./tables/CustomerCreditsTable";
import CashierTabs from "./tabs/CashierTabs";
import Statistics from "../../components/shared/Statistics";
import CustomerCreditsList from "./lists/CustomerCreditsList";
import SheetJSReactAoO from "../reports/OrdersReport";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import "./Cashier.css";

var moment = require('moment');

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Cashier() {
    const {
        actions,
        cashierData,
        deposits,
        spendings,
        vendorOrderStatistics,
        userPermissions
    } = useAppContext();

    const [vendorDeposits, setVendorDeposits] = useState([]);
    const [vendorSpendings, setVendorSpendings] = useState([]);
    const [cashierOrders, setCashierOrders] = useState([]);
    const [customerCredits, setCustomerCredits] = useState([]);
    const [cashierCount, setCashierCounts] = useState({
        cashierOrders: null,
        counts: {
            total_amount: 0,
            payment_total: 0,
            spending_total: 0,
            cashier_balance: 0,
            total_credit: 0
        }
    });
    const [filter, setFilter] = useState({
        from: '',
        to: new Date()
    });
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        actions.onSetSelected('cashier');
        setVendorDeposits(deposits);
        setVendorSpendings(spendings);
        console.log('spendings', spendings);
        setCashierOrders(cashierData.cashierOrders);
        setCashierCounts(cashierData.counts);
        setCustomerCredits(cashierData.customerCredits);
    }, [deposits, cashierData, spendings]);

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...filter,
            [name]: value
        };

        setFilter(newFormInput);

    }

    const applyFilter = async () => {
        let newOrders = [];
        let newDeposits = [];
        let newSpendings = [];
        let fromDate = moment(filter.from).format('L');
        let toDate = moment(filter.to).format('L');

        if (fromDate > toDate) {
            alert('To Date need to be after From Date.');
            return;
        }

        //Orders Filter
        for (let i = 0, n = cashierData.cashierOrders.length; i < n; ++i) {
            let orderDate = moment(cashierData.cashierOrders[i].createdAt).format('L');

            if (orderDate >= fromDate && orderDate <= toDate) {
                newOrders.push(cashierData.cashierOrders[i]);
            }
        }

        //Deposits Filter
        for (let i = 0, n = deposits.length; i < n; ++i) {
            let depositedDate = moment(deposits[i].depositedDate).format('L');

            if (depositedDate >= fromDate && depositedDate <= toDate) {
                newDeposits.push(deposits[i]);
            }
        }

        //Spendings Filter
        for (let i = 0, n = spendings.length; i < n; ++i) {
            let spendingDate = moment(spendings[i].spendingDate).format('L');

            if (spendingDate >= fromDate && spendingDate <= toDate) {
                newSpendings.push(spendings[i]);
            }
        }

        let response = await getVendorCashierData(newOrders, newDeposits, newSpendings);//add deposits in place of array

        setCashierOrders(response.cashierOrders);
        setCashierCounts(response.counts);
        setVendorDeposits(response.vendorDeposits);
        setVendorSpendings(response.vendorSpendings);
    }

    const runExcelReport = async () => {

        let response = await runReport();//add deposits in place of array

    }


    return (
        <div className="Orders">
            {userPermissions.admin || userPermissions.cashier_read || userPermissions.cashier_write ? <>
                <Statistics statistics={vendorOrderStatistics} />
                <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label={<Text>Cashier</Text>} {...a11yProps(0)} />
                        <Tab label={<Text>Customer Credit</Text>} {...a11yProps(1)} />
                        <Tab label={<Text>Expenses</Text>} {...a11yProps(2)} />
                        <Tab label={<Text>Invoices</Text>} {...a11yProps(3)} />
                    </Tabs>

                    <StyledTabPanel value={value} index={0} dir={theme.direction}>
                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={12} md={4}>
                                <h2 className="pb-3 mt-4 mb-3"><Text>Cashier</Text></h2>
                                <Grid item xs={12} sm={12} md={12}>
                                    {/* <Button
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    variant="outlined"
                                    onClick={runExcelReport}>
                                    Run Report
                                </Button> */}
                                    <SheetJSReactAoO
                                        reportData={cashierOrders}
                                        cashierCounts={cashierCount}
                                        deposits={vendorDeposits}
                                        spendings={vendorSpendings}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={8} >
                                <Grid container item spacing={3} >
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            id="date"
                                            name="from"
                                            label={<Text>from date</Text>}
                                            type="date"
                                            lang="fr-FR"
                                            value={filter.from}
                                            onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            id="date"
                                            name="to"
                                            label={<Text>to date</Text>}
                                            type="date"
                                            value={filter.to}
                                            onChange={handleInputChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Button
                                            sx={{
                                                //flexGrow: 1,
                                                marginTop: 1,
                                            }}
                                            variant="outlined"
                                            startIcon={<FilterAltIcon />}
                                            onClick={applyFilter}>
                                            <Text>Apply Filter</Text>
                                        </Button>
                                    </Grid>



                                </Grid>

                            </Grid>

                        </Grid>
                        <div className="border-bottom"></div>
                        <Grid container item spacing={3} sx={{
                            marginTop: 2
                        }}>

                            <Grid item xs={12} sm={12} md={8}>
                                <CashierTable orders={cashierOrders} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <CashierTabs
                                    cashierCounts={cashierCount}
                                    deposits={vendorDeposits}
                                    spendings={vendorSpendings}
                                    customerCredits={cashierOrders}
                                />
                            </Grid>

                        </Grid>
                    </StyledTabPanel>
                    <StyledTabPanel value={value} index={1} dir={theme.direction}>
                        {/* Customer Credits */}
                        <CustomerCreditsTable
                            customerCredits={customerCredits}
                        />
                    </StyledTabPanel>
                    <StyledTabPanel value={value} index={2} dir={theme.direction}>

                        {/* Expenses */}
                        {userPermissions.admin || userPermissions.deposit_write ?
                            <SpendingsDialog
                                btColor={'primary'}
                                btTitle={<Text>Add Output</Text>}
                            />
                            : null}
                        <br />
                        <ExpensesTable
                            expensesData={vendorSpendings}
                        />

                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={3} dir={theme.direction}>
                        {/* Invoices */}
                    </StyledTabPanel>

                </Box>

            </> : null}
        </div>
    );
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
border-radius: 12px;`,
);

