import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
//import InputMask from "react-input-mask";
import { useAppContext } from "../../../lib/contextLib";
import { onError } from "../../../lib/errorLib";
import ConfirmDelete from "../../../components/dialogs/ConfirmDelete";
import LoaderButton from "../../../components/LoaderButton";
import { Text } from '../../../services/translator';

import {
    saveSpendingCategories,
    updateSpendingCategories,
    removeSpendingCategories
} from "../../../services/spending_categories";

export default function SpendingCategoriesForm({ btColor, btTitle, id = 0, spending_category = null }) {

    const {
        actions
    } = useAppContext();
    const [submitting, setSubmitting] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [formInput, setFormInput] = React.useState({
        active: true,
        spendingCategoryName: '',
        spendingCategoryNote: '',
    });

    const [errors, setErrors] = React.useState({
        spendingCategoryName: '',
        spendingCategoryNote: '',
    });

    React.useEffect(() => {
        if (id != 0) {
            setFormInput(spending_category);
        }
    }, [spending_category, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        setFormInput(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.spendingCategoryName === '') {
            newErrors.spendingCategoryName = 'Spending Category Name is required.';
        } else {
            newErrors.spendingCategoryName = '';
        }

        setErrors(newErrors);

        if (
            newErrors.spendingCategoryName.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }


    async function handleSubmit() {
        setSubmitting(true);
        let isValid = validateForm();
        if (isValid) {
            let response = null;
            try {
                if (id == 0) {
                    response = await saveSpendingCategories({ ...formInput });

                    actions.onSetFeedback(true, 'Data was successfully saved.', 'success');
                } else {

                    response = await updateSpendingCategories(id, { ...formInput });

                    actions.onSetFeedback(true, 'Data was successfully updated.', 'success');
                }

                actions.onGetSpendingCategories();
                setSubmitting(false);
                setOpen(false);
            } catch (e) {
                onError(e);
            }
        }

    }

    async function handleRemove() {
        let response = null;
        try {
            setSubmitting(true);
            response = await removeSpendingCategories(id);

            actions.onSetFeedback(true, 'Data was successfully removed.', 'success');

            actions.onGetSpendingCategories();
            setOpen(false);
            setSubmitting(false);
        } catch (e) {
            onError(e);
        }

    }
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10 }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {id !== 0 ? "Update Form: " : "Spending Category Form"}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="spendingCategoryName"
                                    label="Spending Category Name *"
                                    value={formInput.spendingCategoryName}
                                    helperText={errors.spendingCategoryName}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="spendingCategoryNote"
                                    label="Spending Category Note *"
                                    value={formInput.spendingCategoryNote}
                                    helperText={errors.spendingCategoryNote}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                            
                            {id !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <ConfirmDelete
                                    btTitle={'Remove'}
                                    dlTitle={'Confirm Remove'}
                                    message={'Are you sure you want to remove this record.'}
                                    onConfirm={handleRemove}
                                />
                            </Grid>}

                        </Grid>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '-40px' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                        }}>
                            {/* <Button
                                variant="contained"
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button> */}
                            <LoaderButton
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={submitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                <Text>Save</Text>
                            </LoaderButton>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
