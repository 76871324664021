import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import CategoryMultiSelect from "../../../components/forms/CategoryMultiSelect";
import {
    priceFormat,
    getCatName,
    getSupplierName
} from "../../../services/shared";
import { Text } from '../../../services/translator';
import { Colors } from '../../../lib/colors';
import { useAppContext } from "../../../lib/contextLib";
import ProductReceiveDialog from "../dialogs/ProductReceiveDialog";
import ProductAttributesTable from "../../../components/tables/ProductAttributesTable";
var moment = require('moment');


const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDetailsList({ data, newDate, productId, quantity }) {
    const {
        actions,
        productQuantityTypes,
        productLengthUnits,
        userPermissions,
        user,
        suppliers,
        vendor,
        productCategories
    } = useAppContext();

    return (
        <React.Fragment>

            <Grid container spacing={3}>
                <Grid container item spacing={3}>

                    <Grid item xs={4}>
                        <List>
                            <ListItem button>
                                <ListItemText primary={<Text>Product Description</Text>} secondary={data.productDescription} />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Last Origin</Text>}
                                    secondary={getSupplierName(suppliers, data.productOrigin) || 'N/A'}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Product Category</Text>}
                                    secondary={getCatName(productCategories, data.categoryId)}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>

                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Length</Text>}
                                    secondary={data.productLength || 0}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText primary={<Text>Width</Text>} secondary={data.productWidth || 0} />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Height</Text>}
                                    secondary={data.productHeight || 0}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>

                            <ListItem button>
                                <ListItemText primary={<Text>Available Quantity</Text>} secondary={data.availableQuantity} />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Quantity</Text>}
                                    secondary={quantity || 0 + ' ' + data.productQuantityType}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Quantity Alert</Text>}
                                    secondary={data.quantityAlert || 0}
                                />
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={12}>
                        <List>

                            <Divider />
                            <ListItem button>
                                <CategoryMultiSelect
                                    isDisabled={true}
                                    key={newDate}
                                    productId={productId}
                                />
                            </ListItem>

                        </List>
                    </Grid>


                    <Grid item xs={12}>
                        <Divider />
                        <Divider ><Text>INVENTORY</Text></Divider>
                        <Divider />
                    </Grid>

                    <Grid item xs={4}>
                        <List>
                            <ListItem button>
                                <ListItemText primary={<Text>Regular Price</Text>} secondary={priceFormat(data.productPrice || 0)} />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Sales Price</Text>}
                                    secondary={priceFormat(data.productSalesPrice || 0) || 0}
                                />
                            </ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>

                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Sale From</Text>}
                                    secondary={moment(data.productDateSaleFrom).format('L') || 'N/A'}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Sale To</Text>}
                                    secondary={moment(data.productDateSaleTo).format('L') || 'N/A'}
                                />
                            </ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem button>
                                <ListItemText primary={<Text>SKU</Text>} secondary={data.productSKU || 'N/A'} />
                            </ListItem>
                            <Divider />
                            <ListItem button>
                                <ListItemText
                                    primary={<Text>Weight (kg)</Text>}
                                    secondary={data.productWeight || 'N/A'}
                                />
                            </ListItem>

                        </List>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                        <Divider><Text>ATTRIBUTES</Text></Divider>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <ProductAttributesTable
                            key={new Date()}
                            isDisabled={true}
                            onSaveAttribute={(item) => {
                              //  handleSaveAttribute(item);
                            }}
                            onRemoveAttribute={(item) => {
                             //   handleRemoveAttribute(item);
                            }}
                            rows={data.productAttributes}
                        />

                    </Grid>


                </Grid>
            </Grid>

            {/* {userPermissions.receive_products == true && <Grid item xs={12} >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ProductReceiveDialog
                        action={'add'}
                        product={data}
                        productId={productId}
                        onSave={() => {

                        }}
                    />
                </div>
            </Grid>} */}

        </React.Fragment>
    );
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};


const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  
  `,
);
