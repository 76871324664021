import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InputMask from "react-input-mask";
import Autocomplete from '@mui/material/Autocomplete';
import LoaderButton from "../../components/LoaderButton";
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";
var moment = require('moment'); // require

export default function OrderInfo({ formInput, onHandleChange, onSave, onHandleCustomerChange }) {

    const {
        userHasAuthenticated,
        store,
        actions,
        orderStatus,
        shippingMethods,
        paymentMethods,
        customers,
        filteredCustomers,
        states
    } = useAppContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [privateKey, setPrivateKey] = React.useState(new Date);
    const [shippingAddress, setShippingAddress] = React.useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });
    const [defualtShippingAddress] = React.useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });
    const [errors, setFormInput] = React.useState({
        categoryId: '',
        productName: '',
        mainImage: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        vendorId: '',
        productPrice: 0,
        productSalesPrice: 0,
        productDateSaleFrom: '',
        productDateSaleTo: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: 0,
        productQuantityType: '',
        availableQuantity: '',
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',

    });

    React.useEffect(() => {
        setShippingAddress(formInput.shippingAddress);
    }, [formInput, customers]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleCustomerChange(cus) {

        if (cus.customerId != undefined) {

            if (cus.shippingAddresses != undefined) {
                let newFormInput = {
                    ...formInput,
                    ['customerId']: cus.customerId,
                    ['fullName']: cus.fullName,
                    ['email']: cus.email,
                    ['phoneNumber']: cus.phoneNumber,
                    ['shippingAddress']: cus.shippingAddresses
                };

                onHandleCustomerChange(newFormInput);

            } else {
                let newFormInput = {
                    ...formInput,
                    ['customerId']: cus.customerId,
                    ['fullName']: cus.fullName,
                    ['email']: cus.email,
                    ['phoneNumber']: cus.phoneNumber,
                    ['shippingAddress']: defualtShippingAddress
                };
                onHandleCustomerChange(newFormInput);
            }
        }
        setPrivateKey(new Date());

    }

    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...shippingAddress,
            [name]: value
        };
        setShippingAddress(newAddressInput);

        let data = {
            target: {
                name: 'shippingAddress',
                value: newAddressInput
            }
        }
        onHandleChange(data);

    }

    return (
        <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={3} md={3}>
                        <ListItem>
                            <ListItemText primary={<Text>Order Date</Text>} secondary={moment(formInput.createdDate).format('lll')} />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <ListItem>
                            <ListItemText primary={<Text>Order Status</Text>} secondary={formInput.orderStatus} />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <ListItem>
                            <ListItemText primary={<Text>Tracking Number</Text>} secondary={formInput.trackingNumber} />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <ListItem>
                            <ListItemText primary={<Text>Shipping Method</Text>} secondary={formInput.shippingMethod} />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <ListItem>
                            <ListItemText primary={<Text>Payment Method</Text>} secondary={formInput.paymentMethod} />
                        </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <ListItem>
                            <ListItemText primary={<Text>Note</Text>} secondary={formInput.note} />
                        </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ textAlign: 'right', marginTop: 30 }}>

                        {formInput.cancelled != true && formInput.closed != true &&
                            <Button
                                variant="contained"
                                //fullWidth
                                onClick={handleClickOpen}
                            >
                                {<Text>Manage Order Info</Text>}
                            </Button>
                        }

                    </Grid>
                </Grid>

            </List>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    {<Text>Order Details</Text>}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            key={privateKey}
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Order Status</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="orderStatus"
                                        value={formInput.orderStatus}
                                        label="Order Status"
                                        onChange={onHandleChange}
                                    >
                                        {orderStatus.map((status, index) =>
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    //error={errors.productName !== '' ? true : false}
                                    id="outlined-error"
                                    name="trackingNumber"
                                    //helperText={errors.productName}
                                    label={<Text>Tracking Number</Text>}
                                    //defaultValue={formInput.productName}
                                    value={formInput.trackingNumber}
                                    onChange={onHandleChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Shipping Method</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="shippingMethod"
                                        value={formInput.shippingMethod}
                                        label={<Text>Shipping Method</Text>}
                                        onChange={onHandleChange}
                                    >
                                        {shippingMethods.map((shipping, index) =>
                                            <MenuItem key={index} value={shipping}>{shipping}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        //error={errors.productName !== '' ? true : false}
                                        id="outlined-error"
                                        name="shippingPrice"
                                        type="number"
                                        label={<Text>Delivery Fee</Text>}
                                        value={formInput.shippingPrice}
                                        onChange={onHandleChange}
                                        fullWidth
                                        disabled={formInput.orderPlaced}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"> <Text>Payment Method</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="paymentMethod"
                                        value={formInput.paymentMethod}
                                        label={<Text>Payment Method</Text>}
                                        onChange={onHandleChange}
                                    >
                                        {paymentMethods.map((payment, index) =>
                                            <MenuItem key={index} value={payment}>{payment}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Tax Included</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="itemTaxIncluded"
                                        value={formInput.itemTaxIncluded}
                                        label={<Text>Tax Included</Text>}
                                        onChange={onHandleChange}
                                        disabled={formInput.orderPlaced}
                                    >
                                        <MenuItem key={'yes'} value={'yes'}>{'YES'}</MenuItem>
                                        <MenuItem key={'no'} value={'no'}>{'NO'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Typography gutterBottom variant="body1">
                                    {<b><Text>Customer Information</Text></b>}
                                </Typography>
                            </Grid>
                            {/* <InputLabel id="demo-simple-select-label">Customer Info</InputLabel> */}
                            <Grid item xs={12} sm={6} md={6}>

                                <Autocomplete
                                    //freeSolo
                                    //id="free-solo-2-demo"
                                    id="controllable-states-demo"
                                    disableClearable
                                    onChange={(_event, obj) => {
                                        handleCustomerChange(obj);
                                    }}
                                    options={filteredCustomers}
                                    getOptionLabel={option => {
                                    //let text = <p>{{option.fullName + ' - ' + option.customerNumber}}</p>
                                       return option.fullName + ' - ' + option.customerNumber + " Tel: " + option.phoneNumber;
                                    }}
                                    //noOptionsText={'Product not found!'}
                                    renderInput={(params) => (
                                        <TextField
                                            //style={{ marginTop: 5 }}
                                            fullWidth
                                            {...params}
                                            label={<Text>Search For Customer</Text>}
                                            // defaultValue={formInput.customerId}
                                            value={formInput.customerId}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="fullName"
                                    label={<Text>Customer Name</Text>}
                                    value={formInput.fullName}
                                    onChange={onHandleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.phoneNumber}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={onHandleChange}
                                >
                                    {() =>
                                        <TextField
                                            id="outlined-error"
                                            name="phoneNumber"
                                            label={<Text>Customer Phone Number</Text>}
                                            fullWidth />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="email"
                                    label={<Text>Customer Email</Text>}
                                    type={'email'}
                                    //defaultValue={formInput.productName}
                                    value={formInput.email}
                                    onChange={onHandleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography gutterBottom variant="body1">
                                    <b><Text>Shipping Address</Text></b>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    //error={errors.addressLine1 !== '' ? true : false}
                                    id="outlined-error"
                                    name="addressLine1"
                                    label={<Text>Address Line One</Text>}
                                    //defaultValue={formInput.shippingAddress.addressLine1}
                                    value={shippingAddress.addressLine1}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    //error={errors.addressLine1 !== '' ? true : false}
                                    id="outlined-error"
                                    name="addressLine2"
                                    label={<Text>Address Line Two</Text>}
                                    //defaultValue={formInput.shippingAddress.addressLine2}
                                    value={shippingAddress.addressLine2}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <TextField
                                    id="outlined-error"
                                    name="city"
                                    label={<Text>City</Text>}
                                    value={shippingAddress.city}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>State</Text></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="state"
                                        value={shippingAddress.state}
                                        label={<Text>State</Text>}
                                        onChange={(e) => {
                                            handleAddressChange(e);
                                        }}
                                    >
                                        {states.map((s, index) =>
                                            <MenuItem key={index} value={s}>{s}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <TextField
                                    id="outlined-error"
                                    name="zipCode"
                                    label={<Text>Zip Code</Text>}
                                    type={'number'}
                                    value={shippingAddress.zipCode}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="outlined-error"
                                    name="note"
                                    label={<Text>Note</Text>}
                                    //defaultValue={formInput.productName}
                                    value={formInput.note}
                                    onChange={onHandleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Grid item xs={12} sm={6} md={6}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                        ><Text>Cancel</Text></Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                        <LoaderButton
                            style={{ display: "flex", marginLeft: "auto" }}
                            type="submit"
                            size="md"
                            variant="primary"
                            isLoading={isLoading}
                            onClick={() => {
                                setIsLoading(true);
                                onSave();
                                setOpen(false);
                            }}
                        >
                            <Text>Save</Text>
                        </LoaderButton>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
