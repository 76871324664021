import React, { useRef, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import PropTypes from 'prop-types';
import { API, Storage } from "aws-amplify";

function ActionAreaCard({title, attachment, description}) {
    const [attachmentURL, setAttachmentURL] = useState('/no-image.jpeg');

    useEffect(() => {

        async function onLoad() {
            try {
                if (attachment.length > 0) {
                    let newAttachmentURL = await Storage.vault.get(attachment, { level: 'public' });
      
                    setAttachmentURL(newAttachmentURL);
                }
            } catch (e) {
                // onError(e);
            }
        }

        onLoad();
    
    }, []);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={attachmentURL}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
              {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ActionAreaCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    attachment: PropTypes.string,
    
};

ActionAreaCard.defaultProps = {
  title: '',
  description: '',
};

export default ActionAreaCard;