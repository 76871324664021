import { API } from "aws-amplify";
import {
    saveDispatch,
    updateDispatch,
    removeProductDispatch,
    getProductDispatch,
    saveDispatchHistoryProduct,
    saveDispatchHistoryVendor
} from "./products_dispatches";

import {
    getProduct,
    updateProduct
} from "./products";

var moment = require('moment');

/**
* Function for getting all product Transfer for sending vendor
* @param {function} getVendorsTransferProducts
*/
export const getVendorsTransferProducts = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/list/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all product Transfer for requester
* @param {function} getAllProductsTransferRequester
*/
export const getMyTransferProducts = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/requester/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all product Transfer for processor
* @param {function} getPendingTransferProducts
*/
export const getPendingTransferProducts = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/processor/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function to remove product Transfer
* @param {function} removeProductTransfer
*/
export const removeProductTransfer = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_transfer/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for getting a product Transfer
* @param {function} getProductTransfer
*/
export const getProductTransfer = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for saving  a product Transfer
* @param {object} data  
* @param {function} saveProductTransfer
*/
export const saveProductTransfer = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_transfer", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for updating a product Transfer
* @param {function} updateProductTransfer
*/
export const updateProductTransfer = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_transfer/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for received a product Transfer
* @param {function} receiveProductTransfer
*/
export const receiveProductTransfer = async (product) => {
    let response;
    let addHistory = true;
    try {

        for (let i = 0; i < product.transferItems.length; i++) {
            let productDispatch = null;
            if (product.transferItems[i].productDispatchId !== undefined) {
                productDispatch = await getProductDispatch(product.transferItems[i].productDispatchId);

                productDispatch.quantity = +productDispatch.quantity - +product.transferItems[i].productQuantity;

                await updateDispatch(product.transferItems[i].productDispatchId, { ...productDispatch });

                if (addHistory == true) {
                    //Transfer from vendor history
                    await saveProductTransferHistory({
                        recordOwnerId: product.vendorId,
                        ...product,
                    });

                    //Transfer to vendor history
                    await saveProductTransferHistory({
                        recordOwnerId: product.transferTo,
                        ...product,
                    });
                }

                addHistory = false;
                //Get product for receiving vendor
                let disId = product.transferTo + product.transferItems[i].productId;

                try {
                    let disRecord = await getProductDispatch(disId);
                    disRecord.quantity = +disRecord.quantity + +product.transferItems[i].productQuantity;
                    //update if found
                    await updateDispatch(disRecord.productDispatchId, { ...disRecord });

                } catch (err) {
                    productDispatch.quantity = product.transferItems[i].productQuantity;
                    productDispatch.vendorId = product.transferTo;
                    //Remove if not found
                    await saveDispatch({ ...productDispatch });

                    await removeProductTransfer(product.productTransferId);

                    return response;

                }

                await removeProductTransfer(product.productTransferId);

            }
        }



    } catch (err) {
        //alert(err);
        throw 'Cannot connect';
    }

    return response;
};


//************************* HISTORY *******************************/
/**
* Function for getting all product Transfer history for sending vendor
* @param {function} getVendorsTransferProductsHistory
*/
export const getVendorsTransferProductsHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/history/list/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product Transfer history
* @param {function} getProductTransferHistory
*/
export const getProductTransferHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/history/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a product Transfer History
* @param {object} data  
* @param {function} saveProductTransferHistory
*/
export const saveProductTransferHistory = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_transfer/history", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a product Transfer history
* @param {function} updateProductTransferHistory
*/
export const updateProductTransferHistory = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_transfer/history/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove product Transfer history
* @param {function} removeProductTransferHistory
*/
export const removeProductTransferHistory = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_transfer/history/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


