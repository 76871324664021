import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import CustomersTable from "../../components/tables/CustomersTable";
import { Text } from '../../services/translator';
import {
    getLastOrder,
    saveOrder
} from "../../services/orders";
import {
    saveCustomer
} from "../../services/customers";

import "./Customers.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Customers() {
    const history = useHistory();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { store, actions, products, settings, user, customers } = useAppContext();

    const createCustomer = async () => {
        try {
            actions.onSetIsLoading(true);

            let response = await saveCustomer({
                totalSpent: 0.00,
                totalOrders: 0,
                active: true,
            });

            actions.onGetCustomers();
            actions.onGetSettings();
            actions.onSetIsLoading(false);
            history.push(`/a_portal/customers/${response.customerId}`);

        } catch (err) {
            actions.onSetIsLoading(false);
        }

    };
    useEffect(() => {
        actions.onSetSelected('customers');
    }, []);


    return (
        <div className="Customers">
             <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom"><Text>Customers</Text></h2>

                <LinkContainer to="#" onClick={() => {
                    createCustomer();
                }}>
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold"><Text>New Customer</Text></span>
                    </ListGroup.Item>
                </LinkContainer>
                <CustomersTable customersData={customers}/>

            </Box>
        </div>
    );
}