import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Grid from '@mui/material/Grid';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import PendingProductsList from "../../components/lists/PendingProductsList";
import VendorsTransferProductsList from "../../components/lists/VendorsTransferProductsList";
import PendingTransferProductsList from "../../components/lists/PendingTransferProductsList";
import PendingReceiveProductsList from "../../components/lists/PendingReceiveProductsList";
import VendorDeposits from "../../components/lists/VendorDepositsList";

import { Text } from '../../services/translator';
import { Colors } from '../../lib/colors';
import { Divider } from "@mui/material";

export default function PandingApprovals() {
    const {
        isAuthenticated,
        actions,
        pendingProducts,
        vendorsTransferProducts,
        pendingTransferProducts,
        vendor,
        receiveProducts,
        userPermissions,
        pendingApprovals
    } = useAppContext();


    useEffect(() => {
        actions.onSetSelected('pending_approvals');
    }, []);

    function renderLander() {
        return (
            <div className="lander">
                <h1>You are not logged In</h1>
            </div>
        );
    }


    function renderPage() {

        return (
            <div className="approvals">
                <h2 className="pb-3 mt-4 mb-3 border-bottom"><Text>Pending Approvals</Text></h2>
                <Grid container item spacing={3}>
                    {vendor.isMainVendor != true &&
                        <>
                            <Grid item xs={12} sm={6} md={6}>
                                {/* Dispatched products to vendors */}
                                <PendingProductsList
                                    pageTitle={<Text>Pending Products</Text>}
                                    productsData={pendingProducts}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                {/* Requests from other vendors */}
                                <VendorsTransferProductsList
                                    pageTitle={<Text>Vendors Transfer Requests</Text>}
                                    productsData={vendorsTransferProducts}
                                />
                            </Grid>

                            {(userPermissions.admin == true || userPermissions.receive_products == true) &&
                                <>
                                    <Grid item xs={12} sm={6} md={6}>
                                        {/* Products waiting to be recieved*/}
                                        <PendingReceiveProductsList
                                            pageTitle={<Text>Product Receivable</Text>}
                                            showCategory={false}
                                            productsData={receiveProducts.pendingReceives || []}
                                        />
                                    </Grid>
                                </>
                            }

                            {(userPermissions.admin == true || userPermissions.bank_deposit_approval == true) &&
                                <>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <VendorDeposits
                                            pageTitle={<Text>Pending Deposits</Text>}
                                            allData={pendingApprovals}
                                        />
                                    </Grid>
                                </>
                            }

                        </>
                    }

                    {vendor.isMainVendor === true &&
                        <>
                            <Grid item xs={12} sm={6} md={6}>
                                {/* Vendors pending product dispatch */}
                                <PendingReceiveProductsList
                                    pageTitle={<Text>Product Receivable</Text>}
                                    showCategory={false}
                                    productsData={receiveProducts.pendingReceives || []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                {/* Accepted transfers that need confirmation from Main vendor */}
                                <PendingTransferProductsList
                                    pageTitle={<Text>Pending Product Transfers</Text>}
                                    showCategory={false}
                                    productsData={pendingTransferProducts}
                                />
                            </Grid>

                            {(userPermissions.admin == true || userPermissions.bank_deposit_approval == true) &&
                                <>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <VendorDeposits
                                            pageTitle={<Text>Pending Deposits</Text>}
                                            allData={pendingApprovals}
                                        />
                                    </Grid>
                                </>
                            }

                        </>

                    }

                </Grid>
            </div>
        );
    }
    return (
        <div className="Approvals">
            {isAuthenticated ? renderPage() : renderLander()}
        </div>
    );
}