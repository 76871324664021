import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useHistory, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import { LinkContainer } from "react-router-bootstrap";
import {
    priceFormat,
    getSupplierName
} from "../../services/shared";
import { Text } from '../../services/translator';
import ProductCats from "../../containers/product_categories/ProductCats";
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Colors } from '../../lib/colors';
import ProductTransfer from "../dialogs/ProductTransfer";
import LoaderButton from "../LoaderButton";
import ProductReceiveDialog from "../../containers/products/dialogs/ProductReceiveDialog";
import { Divider } from '@mui/material';


export default function GutterlessList({ productsData, pageTitle, showCategory }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories,
        vendors,
        vendor,
        suppliers
    } = useAppContext();
    const [products, setProducts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    function getCatName(categoryId) {
        let name = '';
        if (productCategories && categoryId) {
            productCategories
                .filter(item => item.categoryId == categoryId)
                .map(item => {
                    //  alert(item.categoryName);
                    name = item.categoryName;
                });
        }
        return name;
    }

    function getVendorName(vendorId) {
        let name = '';
        if (vendors && vendorId) {
            vendors
                .filter(item => item.vendorId == vendorId)
                .map(item => {
                    //  alert(item.categoryName);
                    name = item.vendorName;
                });
        }
        return name;
    }
    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <TableFilter
                    onChange={(data) => {
                        setProducts(data);
                    }}
                    type={'productsReceived'}
                    data={productsData}
                />
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 360,
                    '& ul': { padding: 0 },
                }}>


                    <Divider />
                    {products.map((row) => (
                        <div key={row.skId}>
                            <ListItem
                                disableGutters
                                secondaryAction={vendor.isMainVendor == true ?
                                    <ProductReceiveDialog
                                        id={row.productReceivedId}
                                        action={'accept'}
                                        product={row}
                                    /> :
                                    <ProductReceiveDialog
                                        id={row.productReceivedId}
                                        action={'edit'}
                                        product={row}
                                    />

                                }
                            >
                                <ListItemText sx={{
                                    marginLeft: 2
                                }}><Chip label={row.receiveStatus} color="warning" size="large" /></ListItemText>

                            </ListItem>

                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>PRODUCT</Text> : </b> {row.productDescription || 'N/A'} ({getCatName(row.categoryId) || 'N/A'})</small>
                            <br />
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>ORIGIN</Text> : </b>{getSupplierName(suppliers, row.productOrigin)}</small>
                            <br />
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>QUANTITY</Text>:</b> {row.productQuantity} {row.productQuantityType}{'(s)'}</small>
                            <br />
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b>PACKAGING NUMBER:</b> {row.packagingNumber}</small>


                            <Divider />

                        </div>
                    ))}
                    {products && products.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }

                </List>

            </Paper>
        </Box>

    </div>
    );
}
