import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoaderButton from "../../../components/LoaderButton";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
    priceFormat,
    getUserName
} from "../../../services/shared";
import { Text } from '../../../services/translator';
import { useAppContext } from "../../../lib/contextLib";
import {
    calculateCustomerCredit
} from "../../../services/orders";
import { onError } from "../../../lib/errorLib";
import { Divider } from '@mui/material';
var moment = require('moment'); // require

export default function FeesForm({ rows, onSave, order }) {
    const {
        userHasAuthenticated,
        store,
        actions,
        vendors,
        userPermissions,
        paymentMethods,
        user,
        users
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [showSave, setShowSave] = React.useState(false);
    const [showAddTran, setShowAddTran] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [orderTransactions, setOrderTransactions] = React.useState([]);
    const [quantityLeft, setQuantityLeft] = React.useState(0);
    const [formInput, setFormInput] = React.useState({
        orderTransactionId: 'unique' + Date.now(),
        paymentMethod: '',
        TransactionAmount: 0,
        paymentDate: new Date()
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = async (data) => {

        if (window.confirm("Are you sure you want to remove this record?") != true) {
            return;
        }

        let newOrderTransactions = [...orderTransactions];

        const index = newOrderTransactions.indexOf(data);
        if (index > -1) {
            newOrderTransactions.splice(index, 1);
        }

        setOrderTransactions(newOrderTransactions);
        setShowSave(true);
    };

    function handleInputChange(event, data) {

        let { name, value } = event.target;
        let newOrderTransactions = [...orderTransactions];

        const index = newOrderTransactions.indexOf(data);

        let row = newOrderTransactions[index];

        let newRow = {
            ...row,
            [name]: value
        };

        newOrderTransactions[index] = newRow;

        // let qtyLeft = calculateDispatchQty(newOrderTransactions);

        // if (qtyLeft > quantity) {
        //     return onError('Quantity is more than what we have left.');
        // }
        // let total = quantity - qtyLeft;
        // setQuantityLeft(total);
        setOrderTransactions(newOrderTransactions);
        //  onChange(newOrderTransactions);
    }

    const addRow = async () => {
        let newOrderTransactions = [...orderTransactions];
        let data = {
            orderTransactionId: 'unique' + Date.now(),
            paymentMethod: '',
            transactionAmount: 0,
            createdBy: user.userId,
            paymentDate: ''
        };

        let addRow = true;

        newOrderTransactions.forEach(async (element, i) => {//Do not add if there is any open row.
            if (element.paymentMethod == '' || element.transactionAmount == 0 || element.paymentDate == '') {
                addRow = false;
            }
        });

        if (addRow) {
            newOrderTransactions.push(data);
            setOrderTransactions(newOrderTransactions);
        }

    };

    React.useEffect(async () => {
        let res = await calculateCustomerCredit(rows, order.grandTotal);

        if (res.isError === 0 && res.credit === 0) {
            if (order.grandTotal != order.orderCredit || order.closed === true || order.cancelled === true) {
                setShowAddTran(false);
            }
        }
        setOrderTransactions(rows);
    }, [rows]);


    return (
        <div>
            <Button
                sx={{ marginRight: 2 }}
                variant="outlined"
                onClick={handleClickOpen}
            >
                {<Text>Payments</Text>}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {<Text>Payments</Text>} {order.orderTransactions.length <= 0 && <> - <Text>No payment yet</Text></>}
                </DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">

                        <Grid container spacing={1} style={{
                            marginTop: 10
                        }}>

                            {(order.closed != true && order.cancelled != true) && orderTransactions.map((data, index) =>

                                <>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"><Text>Payment Method</Text></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="paymentMethod"
                                                value={data.paymentMethod}
                                                label={<Text>Payment Method</Text>}
                                                onChange={(event) => {
                                                    handleInputChange(event, data);
                                                }}
                                                disabled={index + 1 === orderTransactions.length ? false : true}
                                            >
                                                {paymentMethods.map((payment, index) =>
                                                    <MenuItem key={index} value={payment}>{payment}</MenuItem>
                                                )}

                                            </Select>

                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>

                                        <TextField
                                            id="outlined-error"
                                            name="transactionAmount"
                                            type="number"
                                            label={<Text>Transaction Amount</Text>}
                                            value={data.transactionAmount}
                                            onChange={(event) => {
                                                handleInputChange(event, data);
                                            }}
                                            disabled={index + 1 === orderTransactions.length ? false : true}
                                            fullWidth
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            id="date"
                                            name="paymentDate"
                                            label={<Text>Payment Date</Text>}
                                            type="date"
                                            value={data.paymentDate}
                                            onChange={(event) => {
                                                handleInputChange(event, data);
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={index + 1 === orderTransactions.length ? false : true}
                                            fullWidth
                                        />

                                    </Grid>


                                    <Grid item xs={12} sm={1} md={1}>
                                        {index + 1 === orderTransactions.length &&
                                            <IconButton
                                                style={{
                                                    marginTop: 10,
                                                }}
                                                color="error"
                                                aria-label="remove attribute"
                                                component="span"
                                                onClick={() => {
                                                    handleRemove(data);
                                                }}

                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }

                                    </Grid>
                                </>

                            )}
                            {(order.closed == true || order.cancelled == true) && orderTransactions.map((data, index) =>
                                <>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        <Divider />
                                        <Grid
                                            container
                                            spacing={1}
                                        >
                                            <Grid item xs={3} sm={3} md={3}>
                                                <ListItem>
                                                    <ListItemText primary={<Text>Payment Method</Text>} secondary={data.paymentMethod} />
                                                </ListItem>
                                            </Grid>

                                            <Grid item xs={3} sm={3} md={3}>
                                                <ListItem>
                                                    <ListItemText primary={<Text>Transaction Amount</Text>} secondary={priceFormat(data.transactionAmount)} />
                                                </ListItem>
                                            </Grid>

                                            <Grid item xs={3} sm={3} md={3}>
                                                <ListItem>
                                                    <ListItemText primary={<Text>Payment Date</Text>} secondary={moment(data.paymentDate).format('lll')} />
                                                </ListItem>
                                            </Grid>

                                            <Grid item xs={3} sm={3} md={3}>
                                                <ListItem>
                                                    <ListItemText primary={<Text>Created By</Text>} secondary={getUserName(users, data.createdBy)} />
                                                </ListItem>
                                            </Grid>

                                        </Grid>
                                        <Divider />
                                    </List>
                                </>
                            )}


                            <Divider />

                            <Grid item xs={5} sm={5} md={5}>
                                {showAddTran === true &&
                                    <LoaderButton
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                        type="submit"
                                        size="sm"
                                        variant="primary"
                                        onClick={() => {
                                            addRow();
                                        }}
                                    >
                                        {<Text>ADD TRANSACTION</Text>}
                                    </LoaderButton>
                                }

                            </Grid>

                            <Grid item xs={12} sm={7} md={7}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item xs={12} sm={6} md={6} >
                                        <Text>Customer Credit</Text>:
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right' }}>
                                        {priceFormat(order.orderCredit)}
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} style={{ fontSize: 18 }}>
                                        <b><Text>Grand Total</Text>: </b>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right' }}>
                                        {priceFormat(order.grandTotal)}
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Grid item xs={12} sm={6} md={6}>
                        <Button onClick={handleClose}><Text>Cancel</Text></Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                        {order.closed != true && order.cancelled != true &&
                            <LoaderButton
                                style={{display: "flex", marginLeft: "auto"}}
                                type="submit"
                                size="md"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    setIsLoading(true);

                                    if (orderTransactions.length <= 0 && showSave != true) {
                                        //Fully Paid
                                        onSave(true, orderTransactions);

                                    } else {
                                        //Not fully Paid
                                        onSave(false, orderTransactions);
                                    }
                                    setIsLoading(false);
                                }}
                            >
                                {orderTransactions.length <= 0 && showSave != true ? <Text>Fully Paid</Text> : <Text>Save</Text>}
                            </LoaderButton>
                        }
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
