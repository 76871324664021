import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import {
    priceFormat
} from "../../../services/shared";
import { Text } from '../../../services/translator';
import { Colors } from '../../../lib/colors';

import {
    getProduct
} from "../../../services/products";

import {
    getProductDispatchHistory,
    getVendorDispatchHistory
} from "../../../services/products_dispatches";

import {
    getProductReceivedHistory
} from "../../../services/products_received";
import ProductDetailsList from '../lists/ProductDetailsList'
import ProductsDispatchHistoryTable from '../tables/ProductsDispatchHistoryTable';
import ProductsReceivedHistoryTable from '../tables/ProductsReceivedHistoryTable';
import { useAppContext } from "../../../lib/contextLib";
import ProductReceiveDialog from "./ProductReceiveDialog";


const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDetailsDialog({ id, quantity, isVendor }) {
    const { isAuthenticated, userPermissions, vendor } = useAppContext();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [productAttributes, setProductAttributes] = React.useState([]);
    const [productDispatchHistory, setProductDispatchHistory] = React.useState([]);
    const [productReceivedHistory, setProductReceivedHistory] = React.useState([]);
    const [newDate, setNewDate] = React.useState(new Date());
    const [isLoading, setIsLoading] = React.useState(false);
    const [productImages, setProductImages] = React.useState([]);
    const [formInput, setFormInput] = React.useState({
        categoryId: '',
        productName: '',
        mainImage: '',
        productDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        vendorId: '',
        productPrice: 0,
        productSalesPrice: 0,
        productDateSaleFrom: '',
        productDateSaleTo: '',
        productSKU: '',
        productBarCode: '',
        productQuantity: 0,
        productQuantityType: 'm2',
        availableQuantity: 0,
        soldQuantity: 0,
        productWeight: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productLengthUnit: '',
        productStockTracking: false,
        productStockBackorder: false,
        productStockPreorder: false,
        discontinued: false,
        productPosition: '',
        productOrigin: '',
        receivedDate: new Date(),
        blNumber: '',
        quantityAlert: 0,
        productAttributes: [],
        productTags: [],
        categoryIds: [],
        relatedProducts: [],
        productDispatch: [],
        active: true,
    });

    const handleClickOpen = () => {
        setOpen(true);
        onLoad();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        //   onLoad();
    }, [id]);

    async function onLoad(softLoad) {
        if (softLoad === undefined) {
            setIsLoading(true);
        }

        try {
            let newFormInput = { ...formInput };

            const product = await getProduct(id);

            const {
                categoryId,
                productName,
                mainImage,
                productDescription,
                slug,
                pageTitle,
                metaDescription,
                vendorId,
                productPrice,
                productSalesPrice,
                productDateSaleFrom,
                productDateSaleTo,
                productSKU,
                productBarCode,
                productQuantity,
                productQuantityType,
                availableQuantity,
                soldQuantity,
                productWeight,
                productLength,
                productWidth,
                productHeight,
                productLengthUnit,
                productStockTracking,
                productStockBackorder,
                productStockPreorder,
                discontinued,
                productPosition,
                productOrigin,
                receivedDate,
                blNumber,
                quantityAlert,
                active,
                categoryIds,
                productTags,
                relatedProducts,
                productAttributes,
                productDispatch
            } = product;

            newFormInput.categoryId = categoryId;
            newFormInput.productName = productName;
            newFormInput.mainImage = mainImage;
            newFormInput.productDescription = productDescription;
            newFormInput.slug = slug;
            newFormInput.pageTitle = pageTitle;
            newFormInput.metaDescription = metaDescription;
            newFormInput.vendorId = vendorId;
            newFormInput.productPrice = productPrice;
            newFormInput.productSalesPrice = productSalesPrice;
            newFormInput.productDateSaleFrom = productDateSaleFrom;
            newFormInput.productDateSaleTo = productDateSaleTo;
            newFormInput.productSKU = productSKU;
            newFormInput.productBarCode = productBarCode;
            newFormInput.productQuantity = productQuantity;
            newFormInput.productQuantityType = productQuantityType;
            newFormInput.availableQuantity = availableQuantity || 0;
            newFormInput.soldQuantity = soldQuantity;
            newFormInput.productWeight = productWeight;
            newFormInput.productLength = productLength;
            newFormInput.productWidth = productWidth;
            newFormInput.productHeight = productHeight;
            newFormInput.productLengthUnit = productLengthUnit;
            newFormInput.productStockTracking = productStockTracking;
            newFormInput.productStockBackorder = productStockBackorder;
            newFormInput.productStockPreorder = productStockPreorder;
            newFormInput.discontinued = discontinued;
            newFormInput.productPosition = productPosition;
            newFormInput.productOrigin = productOrigin;
            newFormInput.receivedDate = receivedDate;
            newFormInput.blNumber = blNumber;
            newFormInput.quantityAlert = quantityAlert || 0;
            newFormInput.active = active;
            newFormInput.categoryIds = categoryIds || [];
            newFormInput.productTags = productTags || [];
            newFormInput.relatedProducts = relatedProducts || [];
            newFormInput.productAttributes = productAttributes || [];
            newFormInput.productDispatch = productDispatch || [];

            setFormInput(newFormInput);
            setProductAttributes(productAttributes || []);

            let dispatchStory = await getProductDispatchHistory(id);
            const receivedStory = await getProductReceivedHistory(id);

            if (isVendor) {

                dispatchStory = dispatchStory.filter(item => item.vendorId == vendor.vendorId);
            }

            setProductDispatchHistory(dispatchStory);
            setProductReceivedHistory(receivedStory.receivedProducts);
            setNewDate(new Date());
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                onClick={handleClickOpen}
                style={{
                    marginRight: 5
                }}>
                View
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Product Details
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}

                        {userPermissions.receive_products == true && <Grid item xs={12} >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ProductReceiveDialog
                                    action={'add'}
                                    product={formInput}
                                    productId={id}
                                    onSave={() => {

                                    }}
                                />
                            </div>
                        </Grid>}
                    </Toolbar>
                </AppBar>

                {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}

                <Box sx={{
                    flexGrow: 1,
                    marginTop: 2,
                    marginBottom: 2
                }}
                    style={styles.whiteBox}
                >

                    <AppBar position="static" sx={{
                        borderRadius: 2
                    }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                                background: Colors.navBlue,
                                borderRadius: 2
                            }}
                        >
                            <Tab label={<Text>Product Details</Text>} {...a11yProps(0)} />
                            <Tab label={<Text>Product Dispatch History</Text>} {...a11yProps(1)} />
                            <Tab label={<Text>Product Received History</Text>} {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    <StyledTabPanel value={value} index={0} dir={theme.direction}>

                        <ProductDetailsList
                            data={formInput}
                            productId={id}
                            newDate={newDate}
                            quantity={quantity}
                        />

                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={1} dir={theme.direction}>

                        <ProductsDispatchHistoryTable dispatchesData={productDispatchHistory} />

                    </StyledTabPanel>

                    <StyledTabPanel value={value} index={2} dir={theme.direction}>
                        <ProductsReceivedHistoryTable receivedData={productReceivedHistory} productQuantityType={formInput.productQuantityType} />
                    </StyledTabPanel>

                </Box>


            </Dialog>
        </React.Fragment>
    );
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};


const StyledTabPanel = styled(TabPanel)(
    ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  
  `,
);
