import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Text } from '../../services/translator';
import {
    saveProductCat,
    updateProductCat,
    getProductCat
} from "../../services/product_categories";
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";
import Categories from "../../containers/product_categories/multiSelectList";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const AdditionalCats = (props) => {
    const { categoryId, onDelete, isDisabled } = props;
    const [selectedCat, setSelectedCat] = React.useState({});
    const { userHasAuthenticated, store, actions, productCategories, vendors } = useAppContext();

    React.useEffect(() => {

        async function onLoad() {
            try {
                return productCategories
                    .filter(item => item.categoryId === categoryId)
                    .map(item => setSelectedCat(item));
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [categoryId]);

    return (
        <>
            {isDisabled ?
                <Chip
                    label={selectedCat.categoryName}
                    // onDelete={() => {
                    //    // onDelete(selectedCat.categoryId);
                    // }}
                    // deleteIcon={<DeleteIcon disabled />}
                    variant="outlined"
                /> : <Chip
                    label={selectedCat.categoryName}
                    onDelete={() => {
                        onDelete(selectedCat.categoryId);
                    }}
                    deleteIcon={<DeleteIcon disabled />}
                    variant="outlined"
                />
            }
        </>

    );
};

export default function CategoryMultiSelect({ onSave, onClose, productId, isDisabled }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [showSave, setShowSave] = React.useState(false);
    const [originalCats, setOriginalCats] = React.useState([])
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [catIds, setCatIds] = React.useState([]);
    const { userHasAuthenticated, store, actions, productCategories, vendors } = useAppContext();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        //onClose();
        setCatIds(originalCats);
        onSave(originalCats);
        setOpen(false);
    };

    const handleSave = () => {
        onSave(catIds);
        setOpen(false);
    };

    const handleChange = (categoryId) => {

        setShowSave(true);
        let newSelectedIds = catIds;

        if (newSelectedIds && newSelectedIds.includes(categoryId)) {
            // remove
            newSelectedIds.splice(newSelectedIds.indexOf(categoryId), 1);

            //return;
        } else {
            // add
            newSelectedIds.push(categoryId);
        }

        setCatIds(newSelectedIds);

        onSave(newSelectedIds);
    };

    React.useEffect(() => {

        async function onLoad() {
            try {

                console.log('productId', productId);
                if (productId && productId != '') {
                    const product = await getProduct(productId);

                    const {
                        categoryIds
                    } = product;

                    //alert();
                    setCatIds(categoryIds || []);
                    setOriginalCats(categoryIds || []);
                }

            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [productId]);

    return (
        <>

            <Stack direction="row" alignItems="center" spacing={1}>

                <Chip label={<Text>Additional Categories</Text>} color="primary" variant="outlined" />

                {catIds.map((item, index) =>
                    <AdditionalCats
                        key={item}
                        isDisabled={isDisabled}
                        categoryId={item}
                        onDelete={(id) => {
                            handleChange(id);
                            forceUpdate(); //Force the component to update
                        }}
                    />
                )}

                {!isDisabled && <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClickOpen}
                    disabled={isDisabled}
                >
                    <AddIcon />
                </IconButton>}
            </Stack>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen
                style={{
                    //minWidth: 200, 
                    maxWidth: 350
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Text>Select Other Categories</Text>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Categories
                        onSelect={(id) => {
                            handleChange(id);
                            //  forceUpdate();
                        }}
                        selectedIds={catIds}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        //autoFocus
                        onClick={handleSave}
                        disabled={showSave ? false : true}
                    >
                        <Text>Save</Text>
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </>
    );
}
