import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import { Auth, API } from "aws-amplify";
//import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";

import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import TransferList from "../../components/forms/TransferList";
import { onError } from "../../lib/errorLib";
import { 
    saveUserRole,
    getUserRole,
    updateUserRole
} from "../../services/user_roles";
import {
    saveUserPermission,
    getSingleUserPermission,
    updateUserPermission
} from "../../services/user_permissions";


import "./CreateRoleForm.css";
import { now } from "moment";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function CreateRoleForm() {
    const { id } = useParams();
    const [fields, handleFieldChange] = useState({
        roleName: "",
        shortDescription: "",
    });
    const [updated, setUpdated] = useState(new Date());
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [active, setActive] = useState(true);
    const history = useHistory();
    const { userHasAuthenticated, store, actions, permissions } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        roleName: "",
        shortDescription: "",
    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);

    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (fields.roleName == '') {
            newErrors.roleName = 'Role name is required.';
        } else {
            newErrors.roleName = '';
        }

        setErrors(newErrors);

        if (newErrors.roleName.length > 0) {
            isValid = 0;
        }
        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...fields,
            [name]: value
        };

        handleFieldChange({ ...newFormInput });
        updateErrors(name);
        setShowSave(true);

    }

    async function handleTransferChange(rightData, leftData) {

        let newFormInput = {
            ...fields,
            ['userPermissions']: rightData
        };
        setLeft(leftData);
        setRight(rightData);
        handleFieldChange({ ...newFormInput });
    }

    async function handleSubmit() {

        let isValid = validateForm();

        try {
            if (isValid) {
                setShowSave(false);
                if(id != undefined){
                    let userRole = await updateUserRole(id , {...fields});
                    actions.onSetFeedback(true,'Role was successfully updated.','success');

                }else{
                    actions.onSetIsLoading(true);

                    let userRole = await createUserRole();
    
                    actions.onSetIsLoading(false);

                    actions.onSetFeedback(true,'Role was successfully created.','success');

                    history.push("/a_portal/user_roles/" + userRole.roleId);
                }
                
                actions.onGetUserRoles();
                
            }


        } catch (e) {
            actions.onSetIsLoading(false);
            onError(e);

        }
    }


    async function createUserRole() {

        try {
            const res = await saveUserRole({ ...fields, active });

            return res;
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    useEffect(async () => {
        if (id != undefined) {
            let role = await getUserRole(id);
            handleFieldChange(role);
            //await getUserPermission();

            setRight(role.userPermissions || []);
            await getUserPermission(role.userPermissions || []);
        } else {
            setLeft(permissions);
        }
    }, [id]);

    async function getUserPermission(rightData) {
        let newLeft = [];

        try {

            permissions.forEach(permission => {
                if (permission.permissionValue) {

                    let per = rightData.filter((item) => item.permissionValue == permission.permissionValue);

                    if (per.length <= 0) {
                        newLeft.push(permission);
                    }
                }

            });

            setLeft(newLeft);
            setTimeout(() => {
                setShowSave(false);
            }, 1000);

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        actions.onSetSelected('user_roles');
    }, []);
    useEffect(async () => {
        setUpdated(Date.now());
    }, [left, right]);

    return (
        <div
            className="CreateRoleForm"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //maxWidth: 500
            }}
        >
            <Box
            // sx={{
            //     alignItems: 'center',
            //     flex: 1,
            //     justifyContent: 'center',
            //     //maxWidth: 500
            // }}
            style={styles.whiteBox}
            >
                 <h2 className="pb-3 mb-3 border-bottom"> {id == undefined ? 'Role Create Form': 'Role Update Form'}</h2>
           
            <Grid spacing={1}>

                <Grid container item spacing={3}>

                    <Grid item xs={12} sm={12} md={12}>

                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Active</Typography>
                            <Switch
                                checked={active}
                                onChange={(e) => {
                                    setActive(e.target.checked);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Stack>

                    </Grid>

                </Grid>

                <Grid container item spacing={3}>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.roleName !== '' ? true : false}
                            id="outlined-error"
                            name="roleName"
                            helperText={errors.roleName}
                            label="Role Name"
                            value={fields.roleName}
                            //defaultValue={fields.roleName}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.shortDescription !== '' ? true : false}
                            id="outlined-error"
                            name="shortDescription"
                            helperText={errors.shortDescription}
                            label="Short Description"
                            value={fields.shortDescription}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                </Grid>

                <Grid container item spacing={1}>

                    <Grid item xs={12} sm={12} md={12}>
                        <br />
                        <Typography>Permissions</Typography>
                        <br />
                        <TransferList
                            key={updated}
                            onChange={(rightData, leftData) => {
                                handleTransferChange(rightData, leftData);
                                setShowSave(true);
                            }}
                            leftData={left}
                            rightData={right}
                        />
                    </Grid>

                </Grid>

                <LoaderButton
                    style={{
                        marginTop: 10,
                        marginLeft: 5
                    }}
                    block
                    type="submit"
                    size="lg"
                    variant="primary"
                    isLoading={isLoading}
                    disabled={showSave? false: true}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {id == undefined? 'Create Role': 'Update'}
                </LoaderButton>


            </Grid>
            </Box>
        </div>
    );
}