import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import ActionAreaCard from "../../components/cards/ActionAreaCard";
import { API, Storage } from "aws-amplify";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
//import Divider from '@mui/material/Divider';
import InputMask from "react-input-mask";
import YearSelectDialog from '../../components/dialogs/YearSelectDialog'
import Statistics from "../../components/shared/Statistics";
import VendorTabs from './VendorTabs';
import FeedBack from "../../components/shared/FeedBack";
import { useAppContext } from "../../lib/contextLib";
import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { getVendor, updateVendor } from "../../services/vendors";
import {
    getVendorProducts
} from "../../services/products";
import {
    getVendorOrders
} from "../../services/orders";
import {
    getVendorUsers
} from "../../services/users";
import {
    getProductDispatchHistory,
    getVendorDispatchHistory
} from "../../services/products_dispatches";

import {
    getVendorsTransferProductsHistory
} from "../../services/products_transfer";

import { Text } from '../../services/translator';

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};
export default function VendorDetails() {
    const { id } = useParams();
    const file = useRef(null);
    const history = useHistory();
    const [showPage, setShowPage] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vendorProducts, setVendorProducts] = useState([]);
    const [vendorProductsDispatches, setVendorProductsDispatches] = useState([]);
    const [productDispatchHistory, setProductDispatchHistory] = useState([]);
    const [vendorOrders, setVendorOrders] = useState([]);
    const [vendorOrderStatistics, setVendorOrderStatistics] = useState({});
    const [vendorUsers, setVendorUsers] = useState([]);
    const [productTransferHistory, setProductTransferHistory] = useState([]);
    const { store, actions, userPermissions, states } = useAppContext();

    const [formInput, setFormInput] = useState({
        lastOrderNumber: '',
        vendorName: '',
        profileImage: '',
        profileImageURL: '',
        phoneNumber: '',
        faxNumber: '',
        email: '',
        vendorManager: '',
        currentYear: '',
        active: true,
        isMainVendor: false,
        vendorAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        }
    });
    const [vendorAddress, setVendorAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
    });

    const [errors, setErrors] = useState({
        vendorName: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        city: '',
        state: ''
    });

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.vendorName == '') {
            newErrors.vendorName = 'Store name is required.';
        } else {
            newErrors.vendorName = '';
        }

        if (formInput.phoneNumber == '') {
            newErrors.phoneNumber = 'Phone number is required.';
        } else {
            newErrors.phoneNumber = '';
        }

        if (formInput.email.length < 0) {
            newErrors.email = 'Email address is required.';
        } else {
            if (validator.isEmail(formInput.email)) {
                newErrors.email = '';
            } else {
                newErrors.email = 'Please Enter an valid Email Address.';
            }
        }

        if (vendorAddress.addressLine1 == '') {
            newErrors.addressLine1 = 'Address line one is required.';
        } else {
            newErrors.addressLine1 = '';
        }

        if (vendorAddress.city.length == '') {
            newErrors.city = 'City is required.';
        } else {
            newErrors.city = '';
        }

        setErrors(newErrors);

        if (
            newErrors.vendorName.length > 0 ||
            newErrors.phoneNumber.length > 0 ||
            newErrors.email.length > 0 ||
            newErrors.addressLine1.length > 0 ||
            newErrors.city.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }
    useEffect(() => {
        actions.onSetSelected('vendors');
    }, []);
    useEffect(() => {

        async function onLoad() {

            try {
                //actions.onSetIsLoading(true);
                let newFormInput = { ...formInput };
                const vendor = await getVendor(id);
            
                const {
                    lastOrderNumber,
                    profileImage,
                    vendorName,
                    phoneNumber,
                    faxNumber,
                    email,
                    vendorManager,
                    active,
                    vendorAddress,
                    isMainVendor,
                    currentYear
                } = vendor;
                if (profileImage) {
                    let newImage = await Storage.vault.get(profileImage);
                    newFormInput.profileImageURL = newImage;
                    //await Storage.vault.get(profileImage);
                    newFormInput.profileImage = profileImage;
                }

                newFormInput.lastOrderNumber = lastOrderNumber;
                newFormInput.vendorName = vendorName;
                newFormInput.phoneNumber = phoneNumber;
                newFormInput.faxNumber = faxNumber;
                newFormInput.email = email;
                newFormInput.vendorManager = vendorManager;
                newFormInput.isMainVendor = isMainVendor || false;
                newFormInput.vendorAddress = vendorAddress;
                newFormInput.currentYear = currentYear;
                newFormInput.active = active;
                if (vendorAddress != null) {
                    setVendorAddress(vendorAddress);
                }
                setFormInput(newFormInput);
                setShowPage(true);
                
                const newVendorOrders = await getVendorOrders(id);
                setVendorOrders(newVendorOrders.orders);
                setVendorOrderStatistics(newVendorOrders.statistics);
                const newVendorProducts = await getVendorProducts(id);
                setVendorProducts(newVendorProducts);
                setVendorProductsDispatches(newVendorProducts);
                
                const dispatchHistory = await getVendorDispatchHistory(id);
                setProductDispatchHistory(dispatchHistory);
                const transferHistory = await getVendorsTransferProductsHistory(id);
                setProductTransferHistory(transferHistory);
                const newVendorUsers = await getVendorUsers(id);
                setVendorUsers(newVendorUsers);
                
                actions.onSetIsLoading(false);
            } catch (e) {
                //actions.onSetIsLoading(false);
                onError(e);
            }

            setShowPage(true);

        }

        onLoad();
    }, [id]);

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }

    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...vendorAddress,
            [name]: value
        };

        setVendorAddress(newAddressInput);
        setShowSave(true);
    }

    async function handleFileChange(event) {
        file.current = event.target.files[0];

        if (event.target.files[0]) {
            const profileImage = file.current ? await s3Upload(file.current) : null;

            let newFormInput = {
                ...formInput,
                ['profileImage']: profileImage
            };

            setFormInput(newFormInput);
        }
        setShowSave(true);
    };

    async function handleSubmit() {

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }
        setIsLoading(true);
        try {
            //const profileImage = file.current ? await s3Upload(file.current) : null;
            let response = await updateVendor(id, { ...formInput, vendorAddress: vendorAddress });

            if (response.status) {
                actions.onGetVendors();
                actions.onSetFeedback(true, 'Vendor was successfully updated..', 'success');
                history.push(`/a_portal/vendors/${id}`);
            }

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
        setShowSave(false);
        setIsLoading(false);
    }


    return (
        <div className="VendorForm">
            {showPage === true && <>
                <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>

                    <h2 className="pb-3 mb-3 border-bottom"><Text>Vendor Details Page</Text></h2>
                    <Statistics statistics={vendorOrderStatistics} />
                    <Grid container spacing={1}>

                        <Grid container item spacing={3}>

                            {userPermissions.admin &&
                                <Grid item xs={12} sx={{ marginTop: 2 }}>
                                    <YearSelectDialog
                                        formInput={formInput}
                                        onChange={(data) => {
                                            let newFormInput = {
                                                ...data
                                            };
                                            setFormInput(newFormInput);
                                        }}
                                        onSave={() => {
                                            handleSubmit();
                                        }}
                                        btColor={'primary'}
                                        btTitle={<Text>New Business Year</Text>}
                                        modelTitle={<Text>New Business Year</Text>}
                                        inputLabel={<Text>Business Year</Text>}
                                    />
                                </Grid>
                            }

                            <Grid item xs={6}>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography><Text>Active</Text></Typography>
                                    <Switch
                                        checked={formInput.active}
                                        onChange={(e) => {
                                            let newFormInput = {
                                                ...formInput,
                                                ['active']: e.target.checked
                                            };

                                            setFormInput(newFormInput);
                                            setShowSave(true);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}

                                    />
                                </Stack>

                            </Grid>

                            <Grid item xs={6}>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography><Text>Main Vendor</Text></Typography>
                                    <Switch
                                        checked={formInput.isMainVendor}
                                        onChange={(e) => {
                                            let newFormInput = {
                                                ...formInput,
                                                ['isMainVendor']: e.target.checked
                                            };

                                            setFormInput(newFormInput);
                                            setShowSave(true);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={(userPermissions.admin == true) ? false : true}

                                    />
                                </Stack>

                            </Grid>

                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={6}>

                                <TextField
                                    error={errors.vendorName !== '' ? true : false}
                                    id="outlined-error"
                                    name="vendorName"
                                    helperText={errors.vendorName}
                                    label={<Text>Vendor Name</Text>}
                                    defaultValue={formInput.vendorName}
                                    value={formInput.vendorName}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />

                            </Grid>
                            <Grid item xs={6}>

                                <TextField
                                    error={errors.email !== '' ? true : false}
                                    id="outlined-error"
                                    name="email"
                                    label={<Text>Email</Text>}
                                    defaultValue={formInput.email}
                                    value={formInput.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />

                            </Grid>

                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={6}>
                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.phoneNumber}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                    maskChar=" "
                                    onChange={handleInputChange}
                                >
                                    {() =>
                                        <TextField
                                            error={errors.phoneNumber !== '' ? true : false}
                                            id="outlined-error"
                                            name="phoneNumber"
                                            label={<Text>Phone Number</Text>}
                                            fullWidth
                                        />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6}>
                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.faxNumber}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                    maskChar=" "
                                    onChange={handleInputChange}
                                >
                                    {() =>
                                        <TextField
                                            id="outlined-error"
                                            name="faxNumber"
                                            label={<Text>Fax Number</Text>}
                                            fullWidth
                                        />}
                                </InputMask>

                            </Grid>
                        </Grid>

                        {/* <Divider variant="middle" /> */}
                        <Typography gutterBottom variant="body1">
                            Store Address
                        </Typography>
                        <Grid container item spacing={3}>

                            <Grid item xs={6}>
                                <TextField
                                    error={errors.addressLine1 !== '' ? true : false}
                                    id="outlined-error"
                                    name="addressLine1"
                                    label={<Text>Address Line 1</Text>}
                                    defaultValue={vendorAddress.addressLine1}
                                    value={vendorAddress.addressLine1}
                                    fullWidth
                                    onChange={handleAddressChange}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-error"
                                    name="addressLine2"
                                    label={<Text>Address Line 2</Text>}
                                    defaultValue={vendorAddress.addressLine2}
                                    value={vendorAddress.addressLine2}
                                    fullWidth
                                    onChange={handleAddressChange}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={4}>
                                <TextField
                                    error={errors.city !== '' ? true : false}
                                    id="outlined-error"
                                    name="city"
                                    label={<Text>City</Text>}
                                    defaultValue={vendorAddress.city}
                                    value={vendorAddress.city}
                                    fullWidth
                                    onChange={handleAddressChange}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="state"
                                        value={vendorAddress.state}
                                        label={<Text>State</Text>}
                                        onChange={(e) => {
                                            //console.log('Event', e);
                                            handleAddressChange(e);
                                        }}
                                        disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                    >
                                        {states.map((s, index) =>
                                            <MenuItem key={index} value={s}>{s}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-error"
                                    name="zipCode"
                                    label={<Text>Zip Code</Text>}
                                    defaultValue={vendorAddress.zipCode}
                                    value={vendorAddress.zipCode}
                                    fullWidth
                                    onChange={handleAddressChange}
                                    disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                                />
                            </Grid>
                        </Grid>

                        <Form.Group controlId="file" style={{ marginTop: 10, marginLeft: 10 }}>
                            {/* <Form.Label>Store Profile</Form.Label> */}
                            <ActionAreaCard
                                key={formInput.profileImage}
                                attachment={formInput.profileImage}
                            />
                            <Form.Control
                                onChange={handleFileChange}
                                type="file"
                                disabled={(userPermissions.vendors_write == true || userPermissions.admin == true) ? false : true}
                            />
                        </Form.Group>

                        {showSave && <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            variant="primary"
                            isLoading={isLoading}
                            //disabled={!validateForm()}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            <Text>Save</Text>
                        </LoaderButton>}

                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
                    <VendorTabs
                        products={vendorProducts}
                        dispatches={vendorProductsDispatches}
                        dispatchesHistory={productDispatchHistory}
                        orders={vendorOrders}
                        users={vendorUsers}
                        vendor={formInput}
                        productTransferHistory={productTransferHistory}
                    />
                </Box>
            </>}
        </div>
    );
}