import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import InputMask from "react-input-mask";

import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";
import { saveVendor } from "../../services/vendors";
import "./VendorForm.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function VendorForm() {
    const file = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { store, actions, userPermissions } = useAppContext();
    const [states, setStates] = useState([
        'Dakar',
        'Diourbel',
        'Fatick',
        'Kaffrine',
        'Kaolack',
        'Kédougou',
        'Kolda',
        'Louga',
        'Matam',
        'Saint-Louis',
        'Sédhiou',
        'Tambacounda',
        'Thiès',
        'Ziguinchor'
    ]);

    const [formInput, setFormInput] = useState({
        vendorName: '',
        profileImage: '',
        phoneNumber: '',
        faxNumber: '',
        email: '',
        vendorManager: '',
        active: true,
    });
    const [vendorAddress, setVendorAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });

    const [errors, setErrors] = useState({
        vendorName: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        city: '',
        state: ''
    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);

    }


    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.vendorName == '') {
            newErrors.vendorName = 'Store name is required.';
        } else {
            newErrors.vendorName = '';
        }

        if (formInput.phoneNumber == '') {
            newErrors.phoneNumber = 'Phone number is required.';
        } else {
            newErrors.phoneNumber = '';
        }

        if (formInput.email.length < 0) {
            newErrors.email = 'Email address is required.';
        } else {
            if (validator.isEmail(formInput.email)) {
                newErrors.email = '';
            } else {
                newErrors.email = 'Please Enter an valid Email Address.';
            }
        }

        if (vendorAddress.addressLine1 == '') {
            newErrors.addressLine1 = 'Address line one is required.';
        } else {
            newErrors.addressLine1 = '';
        }

        if (vendorAddress.city.length == '') {
            newErrors.city = 'City is required.';
        } else {
            newErrors.city = '';
        }

        setErrors(newErrors);

        if (
            newErrors.vendorName.length > 0 ||
            newErrors.phoneNumber.length > 0 ||
            newErrors.email.length > 0 ||
            newErrors.addressLine1.length > 0 ||
            newErrors.city.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        updateErrors(name);

    }

    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...vendorAddress,
            [name]: value
        };

        setVendorAddress(newAddressInput);
        updateErrors(name);
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];

        //console.log('event.target.files[0]', event.target.files[0]);
    }

    async function handleSubmit() {
        let isValid = validateForm();

        if (isValid) {
            if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
                alert(
                    `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
                    } MB.`
                );
                return;
            }
            setIsLoading(true);
            actions.onSetIsLoading(true);
            try {
                const profileImage = file.current ? await s3Upload(file.current) : null;
                await saveVendor({ ...formInput, vendorAddress: vendorAddress, profileImage });
                actions.onGetVendors();
                actions.onSetIsLoading(false);
                history.push("/a_portal/vendors");
            } catch (e) {
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
        }

    }

    function createVendor(data) {
        return saveVendor(data);
    };

    useEffect(() => {
        actions.onSetSelected('vendors');
    }, []);

    return (
        <div className="VendorForm">
            {(userPermissions.vendors_write == true || userPermissions.admin == true) &&
                <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
                    <h2 className="pb-3 mb-3 border-bottom"> {'Vendor Create Form'}</h2>
                    <Grid container spacing={1}>

                        <Grid container item spacing={3}>

                            <Grid item xs={6}>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Active</Typography>
                                    <Switch

                                        checked={formInput.active}
                                        onChange={(e) => {
                                            let newFormInput = {
                                                ...formInput,
                                                ['active']: e.target.checked
                                            };

                                            setFormInput(newFormInput);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}

                                    />
                                </Stack>

                            </Grid>

                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={6} md={6}>

                                <TextField
                                    error={errors.vendorName !== '' ? true : false}
                                    id="outlined-error"
                                    name="vendorName"
                                    helperText={errors.vendorName}
                                    label="Vendor Name"
                                    defaultValue={formInput.vendorName}
                                    onChange={handleInputChange}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>

                                <TextField
                                    error={errors.email !== '' ? true : false}
                                    id="outlined-error"
                                    name="email"
                                    helperText={errors.email}
                                    label="Email"
                                    defaultValue={formInput.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                />

                            </Grid>

                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={6} md={6}>
                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.phoneNumber}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={handleInputChange}
                                >
                                    {() =>
                                        <TextField
                                            error={errors.phoneNumber !== '' ? true : false}
                                            id="outlined-error"
                                            name="phoneNumber"
                                            helperText={errors.phoneNumber}
                                            label="Phone Number"
                                            fullWidth
                                        />}
                                </InputMask>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <InputMask
                                    mask="99 999 99 99"
                                    value={formInput.faxNumber}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={handleInputChange}
                                >
                                    {() =>
                                        <TextField
                                            id="outlined-error"
                                            name="faxNumber"
                                            label="Fax Number"
                                            fullWidth
                                        />}
                                </InputMask>

                            </Grid>
                        </Grid>

                        {/* <Divider variant="middle" /> */}
                        <Typography gutterBottom variant="body1">
                            Store Address
                        </Typography>
                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    error={errors.addressLine1 !== '' ? true : false}
                                    id="outlined-error"
                                    name="addressLine1"
                                    helperText={errors.addressLine1}
                                    label="Address Line One"
                                    defaultValue={vendorAddress.addressLine1}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="addressLine2"
                                    label="Address Line Two"
                                    defaultValue={vendorAddress.addressLine2}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={3}>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    error={errors.city !== '' ? true : false}
                                    id="outlined-error"
                                    name="city"
                                    helperText={errors.city}
                                    label="City"
                                    defaultValue={vendorAddress.city}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                {/* <TextField
                                error
                                id="outlined-error"
                                name="state"
                                label="State"
                                defaultValue=""
                                fullWidth
                                onChange={handleInputChange}
                            /> */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="state"
                                        value={vendorAddress.state}
                                        label="State"
                                        onChange={(e) => {
                                            //console.log('Event', e);
                                            handleAddressChange(e);
                                        }}
                                    >
                                        {states.map((s, index) =>
                                            <MenuItem key={index} value={s}>{s}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-error"
                                    name="zipCode"
                                    label="Zip Code"
                                    defaultValue={vendorAddress.zipCode}
                                    fullWidth
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                        </Grid>

                        <Form.Group controlId="file">
                            <Form.Label>Store Profile</Form.Label>
                            <Form.Control onChange={handleFileChange} type="file" />
                        </Form.Group>

                        <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            variant="primary"
                            isLoading={isLoading}
                            // disabled={!validateForm()}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Create Vendor
                        </LoaderButton>

                    </Grid>
                </Box>
            }

        </div>
    );
}