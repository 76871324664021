import { API } from "aws-amplify";

/**
* Function for getting a user role
* @param {function} getUserRole 
*/
export const getUserRole = async (id) => {

    let response;

    try {
        response = await API.get("adminAPI", "/admin/user_roles/"+id);
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all user roles 
* @param {function} getAllUserRoles 
*/
export const getAllUserRoles = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/user_roles");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a user role
* @param {object} data  
* @param {function} saveUserRole 
*/
export const saveUserRole = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/user_roles", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a user role 
* @param {function} updateUserRole 
*/
export const updateUserRole = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/user_roles/${id}`, {
            body: data
        });

    } catch (err) {
        console.log('Error Vendor',err);
        throw 'Cannot connect';
    }

    return response;
};