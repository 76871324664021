import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import ActionAreaCard from "../../components/cards/ActionAreaCard";
import { API, Storage } from "aws-amplify";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import FeedBack from "../../components/shared/FeedBack";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import CategorySelect from "../../components/forms/CategorySelect";

import { useAppContext } from "../../lib/contextLib";
import {
    saveProductCat,
    updateProductCat,
    getProductCat
} from "../../services/product_categories";
import "./ProductCatForm.css";

import ButtonBase from '@mui/material/ButtonBase';

export default function ProductCatEditForm() {
    const file = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { userHasAuthenticated, store, actions, productCategories, vendors } = useAppContext();
    const [formInput, setFormInput] = useState({
        //categoryImageURL: '',
        categoryName: '',
        categoryImage: '',
        categoryDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: '',
        parentCategoryId: '',
        active: true,
    });
    const [errors, setErrors] = useState({
        categoryName: '',
        categoryDescription: '',
        slug: '',
        pageTitle: '',
        metaDescription: ''
    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.categoryName == '') {
            newErrors.categoryName = 'Category name is required.';
        } else {
            newErrors.categoryName = '';
        }

        setErrors(newErrors);

        if (
            newErrors.categoryName.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        updateErrors(name);
    }

    async function handleFileChange(event) {
        file.current = event.target.files[0];

        if (event.target.files[0]) {
            const categoryImage = file.current ? await s3Upload(file.current) : null;

            let newFormInput = {
                ...formInput,
                ['categoryImage']: categoryImage
            };

            setFormInput(newFormInput);

            await updateProductCat(id, { categoryImage });
            actions.onGetProductCats();
        }

    };

    async function handleSubmit() {
        let isValid = validateForm();

        if (isValid) {
            if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
                alert(
                    `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
                    } MB.`
                );
                return;
            }
            setIsLoading(true);
            // actions.onSetIsLoading(true);
            try {
                //const categoryImage = file.current ? await s3Upload(file.current) : null;
                await updateProductCat(id, { ...formInput });
                actions.onGetProductCats();
                //actions.onSetIsLoading(false);
                setShowFeedback(true);
                setFeedbackMessage('Category was successfully updated.');
                setFeedbackType('success');
                history.push(`/a_portal/product_categories/${id}`);
            } catch (e) {
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
            setShowFeedback(false);
            setIsLoading(false);
        }

    }

    useEffect(() => {

        async function onLoad() {
            try {
                let newFormInput = { ...formInput };
                const productCat = await getProductCat(id);

                const {
                    categoryImage,
                    categoryName,
                    categoryDescription,
                    slug,
                    pageTitle,
                    metaDescription,
                    active,
                    parentCategoryId
                } = productCat;

                if (categoryImage) {
                    //newFormInput.categoryImageURL = 
                   // await Storage.vault.get(categoryImage);
                    newFormInput.categoryImage = categoryImage;
                }

                newFormInput.categoryName = categoryName;
                newFormInput.categoryDescription = categoryDescription;
                newFormInput.slug = slug;
                newFormInput.pageTitle = pageTitle;
                newFormInput.metaDescription = metaDescription;
                newFormInput.parentCategoryId = parentCategoryId;
                newFormInput.active = active;

                setFormInput(newFormInput);

            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    return (
        <div className="VendorForm">

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>

                    <Grid container item spacing={3}>

                        <Grid item xs={6}>

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Active</Typography>
                                <Switch
                                    checked={formInput.active}
                                    onChange={(e) => {
                                        let newFormInput = {
                                            ...formInput,
                                            ['active']: e.target.checked
                                        };

                                        setFormInput(newFormInput);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.categoryName !== '' ? true : false}
                            id="outlined-error"
                            name="categoryName"
                            helperText={errors.categoryName}
                            label="Category Name"
                            //defaultValue={formInput.categoryName}
                            value={formInput.categoryName}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.categoryDescription !== '' ? true : false}
                            id="outlined-error"
                            name="categoryDescription"
                            helperText={errors.categoryDescription}
                            label="Category Description"
                            //defaultValue={formInput.categoryDescription}
                            value={formInput.categoryDescription}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.slug !== '' ? true : false}
                            id="outlined-error"
                            name="slug"
                            helperText={errors.slug}
                            label="Slug"
                            //defaultValue={formInput.slug}
                            value={formInput.slug}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <small>
                            The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.
                        </small>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.pageTitle !== '' ? true : false}
                            id="outlined-error"
                            name="pageTitle"
                            helperText={errors.pageTitle}
                            label="Page Title"
                            //defaultValue={formInput.pageTitle}
                            value={formInput.pageTitle}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                        <TextField
                            error={errors.metaDescription !== '' ? true : false}
                            id="outlined-error"
                            name="metaDescription"
                            helperText={errors.metaDescription}
                            label="Meta Description"
                            //defaultValue={formInput.metaDescription}
                            value={formInput.metaDescription}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth >
                            <CategorySelect
                                selectedId={formInput.parentCategoryId}
                                onSave={(item) => {
                                    let newFormInput = {
                                        ...formInput,
                                        ['parentCategoryId']: item.categoryId
                                    };
                            
                                    setFormInput(newFormInput);
                                }}
                            />

                        </FormControl>
                    </Grid>

                    <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Label>Category Image</Form.Label>

                        <ActionAreaCard
                            key={new Date()}
                            attachment={formInput.categoryImage}
                        />

                        <Form.Control onChange={handleFileChange} type="file" size="lg" />
                    </Form.Group>

                    <LoaderButton
                        block
                        type="submit"
                        size="lg"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={formInput.categoryName.length > 0 ? false : true}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Save
                    </LoaderButton>

                </Grid>
            </Box>

            {showFeedback &&
                <FeedBack
                    message={feedbackMessage}
                    type={feedbackType}
                />
            }
        </div>
    );
}