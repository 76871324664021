import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import LoaderButton from "../../components/LoaderButton";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { Text } from '../../services/translator';

import {
    getProductDispatch
} from "../../services/products_dispatches";
import {
    saveProductTransfer,
    getProductTransfer,
    updateProductTransfer,
    removeProductTransfer,
    receiveProductTransfer
} from "../../services/products_transfer";
import {
    getVendorName,
    getUserName
} from "../../services/shared";

var moment = require('moment');


export default function ProductTransfer({ onSave, product, productId, status, showForm = true, id = 0, btTitle, btColor }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        vendors,
        vendor,
        user,
        users,
        productQuantityTypes,
    } = useAppContext();

    const [open, setOpen] = useState(false);
    const [showSave, setShowSave] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [productDispatch, setProductDispatch] = useState({});
    const [formInput, setFormInput] = useState({
        vendorId: '', // Transfer from
        productId: '',
        categoryId: '',
        productDescription: '',
        productDispatchId: '',
        transferTo: '',
        transferProcessor: '',
        blNumber: '',
        transferDate: Date.now(),
        productQuantity: 0,
        productQuantityType: '',
        transferReason: '',
        transferStatus: '',
        requestedBy: '',
        acceptedBy: null,
        confirmedBy: null,
        acceptedDate: null,
        confirmedDate: null
    });

    useEffect(async () => {
        // console.log('product',product);
        if (id == 0) {
            if (status == 'accepted') {
                let newFormInput = {
                    ...formInput,
                    ['transferDate']: Date.now(),
                    ['vendorId']: vendor.vendorId,
                    ['productId']: product.productId,
                    ['productDispatchId']: product.productDispatchId,
                    ['productQuantityType']: product.productQuantityType,
                    ['productDescription']: product.productDescription,
                    ['categoryId']: product.categoryId,
                    ['requestedBy']: user.userId,
                    ['acceptedBy']: user.userId,
                    ['acceptedDate']: Date.now(),
                    ['transferStatus']: 'accepted',

                };

                setFormInput(newFormInput);
            } else if (status == 'requested') {
                let newFormInput = {
                    ...formInput,
                    ['transferDate']: Date.now(),
                    ['vendorId']: product.vendorId,
                    ['productId']: product.productId,
                    ['productDispatchId']: product.productDispatchId,
                    ['productQuantityType']: product.productQuantityType,
                    ['productDescription']: product.productDescription,
                    ['categoryId']: product.categoryId,
                    ['requestedBy']: user.userId,
                    ['transferTo']: vendor.vendorId,
                    ['transferStatus']: 'requested',
                };

                setFormInput(newFormInput);
            }
        } else {
            let productDispatchRecord = await getProductDispatch(product.productDispatchId);
            let res = await getProductTransfer(id);
            setFormInput({ ...res });
            setProductDispatch(productDispatchRecord);
        }

    }, [product, user, vendor, status, id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSave = async () => {
        let newFormInput = { ...formInput };

        if (id == 0) {
            if (+newFormInput.productQuantity > +product.productQuantity) {
                onError('Not enough products.');
                return;
            }

            setIsLoading(true);
            setShowSave(false);

            let res = await saveProductTransfer({ ...newFormInput });

            actions.onSetFeedback(true, 'Product transfer was successfully created.', 'success');

        } else {

            if (+newFormInput.productQuantity > +productDispatch.productQuantity) {
                onError('Not enough products.');
                return;
            }

            setIsLoading(true);
            setShowSave(false);
            let res = await updateProductTransfer(id, { ...newFormInput });

            actions.onSetFeedback(true, 'Product transfer was successfully updated.', 'success');

        }

        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        onSave();
        setOpen(false);
    };


    const handleStatusUpdate = async (requestStaus) => {
        let newFormInput = { ...formInput };

        if (+newFormInput.productQuantity > +productDispatch.productQuantity) {
            onError('Not enough products.');
            return;
        }

        setIsLoading(true);
        setShowSave(false);
        let feedB = '';

        if (requestStaus == 'accept') {
            newFormInput.transferStatus = 'accepted';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully accepted.'
        } else if (requestStaus == 'decline') {
            newFormInput.transferStatus = 'declined';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully declined.'
        } else if (requestStaus == 'confirm') {
            newFormInput.transferStatus = 'confirmed';
            newFormInput.confirmedBy = user.userId;
            newFormInput.confirmedDate = Date.now();
            feedB = 'Product transfer was successfully confirmed.'
        }
        let res = await updateProductTransfer(id, { ...newFormInput });

        actions.onSetFeedback(true, feedB, 'success');

        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    const handleCancel = async () => {

        setIsLoading(true);
        setShowSave(false);

        await removeProductTransfer(id);
        actions.onSetFeedback(true, 'Product transfer was successfully cancelled.', 'success');
        actions.onGetProductTransfers(vendor.vendorId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    const handleReceive = async (data) => {

        setIsLoading(true);
        setShowSave(false);

        await receiveProductTransfer(data);
        actions.onSetFeedback(true, 'Product transfer was successfully received.', 'success');
        actions.onGetProductTransfers(vendor.vendorId);
        actions.onGetProducts(vendor.vendorId);
        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);

    }

    return (
        <div>{showForm === true ?

            <div key={'jhkhjkuhihjiuj987879'}>
                <Button style={{marginRight: 15}} variant="outlined" onClick={handleClickOpen} color={btColor}>
                    {btTitle}
                </Button>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {status == 'accepted' ? <Text>Product Transfer</Text> : <Text>Request Product Transfer</Text>} | {status == 'accepted' ? getVendorName(vendors, vendor.vendorId) : getVendorName(vendors, product.vendorId)}
                    </DialogTitle>
                    <DialogContent>
                        {formInput.transferTo == vendor.vendorId &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 &&
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={handleCancel}
                                    >
                                        <Text>Cancel Request</Text>
                                    </LoaderButton>
                                }
                            </Typography>
                        }

                        {status == 'needAcceptance' && product.transferStatus != 'declined' &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 &&
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={() => {
                                            handleStatusUpdate('decline');
                                        }}
                                    >
                                        <Text>DECLINE REQUEST</Text>
                                    </LoaderButton>
                                }
                            </Typography>
                        }



                        <Grid container spacing={3} style={{ marginTop: 5 }}>

                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    autoFocus
                                    id="transferDate"
                                    name="transferDate"
                                    label={<Text>Transfer Date</Text>}
                                    type="date"
                                    fullWidth
                                    //  variant="standard"
                                    defaultValue={formInput.transferDate}
                                    onChange={handleInputChange}
                                />

                            </Grid>

                            {(status == 'accepted' || status == 'needAcceptance') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        id="blNumber"
                                        name="blNumber"
                                        label={<Text>BL Number</Text>}
                                        fullWidth
                                        //variant="standard"
                                        defaultValue={formInput.blNumber}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            }

                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Transfer To</Text></InputLabel>
                                    <Select
                                        //variant="standard"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="transferTo"
                                        value={formInput.transferTo}
                                        label="Transfer To"
                                        onChange={handleInputChange}
                                    >
                                        {vendors.map((vendor, index) =>
                                            <MenuItem key={index + vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(status == 'accepted' || status == 'needAcceptance') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Approver</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="transferProcessor"
                                            value={formInput.transferProcessor}
                                            onChange={handleInputChange}
                                        >
                                            {vendors.map((vendor, index) =>
                                                vendor.isMainVendor == true && <MenuItem key={'tyhgh' + index + vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }


                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    id="productQuantity"
                                    name="productQuantity"
                                    label={<Text>Quantity</Text>}
                                    fullWidth
                                    //type={'number'}
                                    //variant="standard"
                                    defaultValue={formInput.productQuantity}
                                    onChange={handleInputChange}
                                />

                            </Grid>


                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"><Text>Quantity Type</Text></InputLabel>
                                    <Select
                                        disabled
                                        //variant="standard"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="productQuantityType"
                                        value={formInput.productQuantityType}
                                        //label="Quantity Type"
                                        onChange={handleInputChange}
                                    >
                                        {productQuantityTypes.map((type, index) =>
                                            <MenuItem key={'45453ds' + index + 'fderr' } value={type}>{type}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="transferReason"
                                    name="transferReason"
                                    label={<Text>Transfer Reason</Text>}
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    fullWidth
                                    defaultValue={formInput.transferReason}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        {status == 'accepted' ?
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                disabled={
                                    showSave &&
                                        (formInput.transferTo && formInput.transferTo.length > 0) &&
                                        (formInput.transferProcessor && formInput.transferProcessor.length > 0) &&
                                        (formInput.blNumber && formInput.blNumber.length > 0) &&
                                        (formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                                        (formInput.productQuantity && formInput.productQuantity.length > 0) &&
                                        (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                                onClick={handleSave}
                            >
                                <Text>Save</Text>
                            </LoaderButton>
                            : (status == 'needAcceptance' && product.transferStatus != 'declined' && product.transferStatus != 'accepted') ?
                                <LoaderButton
                                    type="submit"
                                    size="sm"
                                    variant="primary"
                                    isLoading={isLoading}
                                    disabled={
                                        showSave &&
                                            (formInput.transferTo && formInput.transferTo.length > 0) &&
                                            (formInput.transferProcessor && formInput.transferProcessor.length > 0) &&
                                            (formInput.blNumber && formInput.blNumber.length > 0) &&
                                            (formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                                            (formInput.productQuantity && formInput.productQuantity.length > 0) &&
                                            (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                                    onClick={() => {
                                        handleStatusUpdate('accept');
                                    }}
                                >
                                    ACCEPT REQUEST
                                </LoaderButton>
                                : (product.transferStatus != 'declined' && product.transferStatus != 'accepted') ?
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="primary"
                                        isLoading={isLoading}
                                        disabled={
                                            showSave &&
                                                (formInput.transferTo && formInput.transferTo.length) > 0 &&
                                                (formInput.productQuantityType && formInput.productQuantityType.length > 0) &&
                                                (formInput.productQuantity && formInput.productQuantity.length > 0) &&
                                                (formInput.transferDate && formInput.transferDate.length > 0) ? false : true}
                                        onClick={handleSave}
                                    >
                                        <Text>Save</Text>
                                    </LoaderButton> : null
                        }

                    </DialogActions>
                </Dialog>
            </div>
            :
            <div>
                <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{marginRight: 15}}>
                    {btTitle}
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {'Product Transfer'} {' | '}
                        {product.transferStatus == 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                            product.transferStatus == 'confirmed' ? <Chip label={<Text>confirmed</Text>} color="success" size="small" /> :
                                product.transferStatus == 'requested' ? <Chip label={<Text>requested</Text>} size="small" /> :
                                    product.transferStatus == 'accepted' ? <Chip label={<Text>accepted</Text>} color="primary" size="small" /> :
                                        null

                        }

                    </DialogTitle>

                    <Divider variant="middle" />
                    <DialogContent>
                        <Grid container spacing={3} >
                            <Box sx={{ width: '100%', maxWidth: 560, minWidth: 460, bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>BL Number</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {product.blNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred From</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.vendorId)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred To</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.transferTo)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Product Description</Text>: 
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {product.productDescription}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Quantity</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {product.productQuantity} {product.productQuantityType}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(product.acceptedDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">

                                                {getUserName(users, product.acceptedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(product.acceptedDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getUserName(users, product.acceptedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Approver</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, product.transferProcessor)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Typography color="text.secondary" variant="body2">
                                        {product.transferReason}
                                    </Typography>
                                </Box>
                                <Divider variant="middle" />

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        {(product.transferTo == vendor.vendorId && product.transferStatus != 'accepted' && product.transferStatus != 'confirmed') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="danger"
                                isLoading={isLoading}
                                onClick={handleCancel}
                            >
                                {status !== 'needRemove' ?
                                    <Text>Cancel Request</Text> :
                                    <Text>confirm</Text>
                                }


                            </LoaderButton>
                        }
                        {(status == 'needAcceptance') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleStatusUpdate('confirm');
                                }}
                            >
                                {<Text>confirm</Text>}
                            </LoaderButton>
                        }



                        {(status == 'needReceive') &&
                            <LoaderButton
                                //block
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleReceive(product);
                                }}
                            >
                                {<Text>Receive</Text>}
                            </LoaderButton>
                        }

                    </DialogActions>
                </Dialog>
            </div>
        }


        </div>
    );
}
