import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
// import ImageIcon from '@mui/icons-material/Image';
// import WorkIcon from '@mui/icons-material/Work';
// import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { LinkContainer } from "react-router-bootstrap";
import QRCode from "react-qr-code";
import OrderItem from './OrderItem';
import { useAppContext } from "../../lib/contextLib";

import {
    priceFormat,
    getCusAccountNumber
} from "../../services/shared";
import { Text } from '../../services/translator';
var moment = require('moment'); // require
//var QRCode = require('qrcode.react');

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
};


export default function OrderInvoiceDetails({ formInput, onRemove, onHandleSave }) {

    // const [open, setOpen] = React.useState(false);
    const { 
        vendor,
        customers
     } = useAppContext();

    React.useEffect(() => {
        // console.log('orderItems', formInput.orderItems);
    }, [formInput]);

    return (
        <div style={{ margin: 40 }}>
            <div style={styles.center}>
                <img
                    width="300"
                    src={`${'/logo3.png'}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${'/logo3.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={'title'}
                    loading="lazy"
                />

            </div>
            <div style={styles.center}>
                {vendor.vendorAddress.addressLine1} {vendor.vendorAddress.addressLine2.length > 0 ? ' ' + vendor.vendorAddress.addressLine2 + ',' : ','} {vendor.vendorAddress.city + ','} {vendor.vendorAddress.state}
            </div>
            <div style={styles.center}>
                {vendor.email}
            </div>
            <div style={styles.center}>
                {vendor.phoneNumber}
            </div>
            <Divider />

            <Grid
                container
                spacing={2}
            >

                <Grid item xs={9} sm={9} md={9}>
                    <p style={{fontSize: 20}}><b><Text>Customer Information</Text></b></p>
                    <LinkContainer to={formInput.customerId && formInput.customerId.length > 0 ? `/a_portal/customers/${formInput.customerId}` : '#'}>
                        <div>
                            <p><b><Text>Customer Account Number</Text>:</b> <a href="#">{getCusAccountNumber(customers, formInput.customerId)} </a> </p>
                            <p><b><Text>Customer Name</Text>: </b>{formInput.fullName}</p>
                            <p><b><Text>Customer Phone Number</Text>:</b> {formInput.phoneNumber}</p>
                            <p><b><Text>Customer Address</Text>: </b>{formInput.shippingAddress.city != undefined && formInput.shippingAddress.city != '' && formInput.shippingAddress.city + ', ' } {formInput.shippingAddress.state}</p>
                        </div>

                    </LinkContainer>

                </Grid>
                <Grid item xs={3} sm={3} md={3} >
                    <div >
                        <p style={{fontSize: 20}}><b><Text>Order</Text></b></p>
                        <p><b><Text>Order Number</Text>:</b> {formInput.orderNumber}</p>
                        <p><b><Text>Order Date</Text>:</b> {moment(formInput.createdDate).format('L')}</p>
                        <p><b><Text>Payment Method</Text>: </b>{formInput.paymentMethod}</p>
                        <p><b><Text>Shipping Method</Text>: </b>{formInput.shippingMethod}</p>
                    </div>

                </Grid>
            </Grid>

            {/* <Divider /> */}
            {/* {formInput.orderItems.length > 0? 

            } */}
            <List
                //dense
                sx={{
                    width: '100%',
                    //maxWidth: 360,
                    minHeight: 900,
                    bgcolor: 'background.paper',
                    border: 1
                }}
            >
                <ListItem sx={{ borderBottom: 1 }} >
                    <Grid
                        container
                    //spacing={2}
                    >
                        {/* <Grid item xs={1} sm={1} md={1} sx={{ borderRight: 2 }} >
                            <ListItemAvatar >
                                {''}
                            </ListItemAvatar>
                        </Grid> */}
                        <Grid item xs={5} sm={5} md={5} sx={{ borderRight: 2, marginTop: -2, marginBottom: -1 }}>
                            <ListItemText id={`checkbox-list-secondary-label-${"jjo339"}`} ><span style={{ fontWeight: 'bold', fontSize: 18, marginLeft: 10 }}> <Text>DESCRIPTION</Text></span></ListItemText>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} sx={{ borderRight: 2, marginTop: -2, marginBottom: -1 }} >
                            <ListItemText id={`checkbox-list-secondary-label-${"price"}`} ><span style={{ fontWeight: 'bold', fontSize: 18, margin: 10 }}><Text>PRICE</Text></span></ListItemText>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} sx={{ borderRight: 2, marginTop: -2, marginBottom: -1 }}>
                            <ListItemText id={`checkbox-list-secondary-label-${"quantity"}`}><span style={{ fontWeight: 'bold', fontSize: 18, margin: 10 }}><Text>QUANTITY</Text></span></ListItemText>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} sx={{ marginTop: -2, marginBottom: -1 }}>
                            <ListItemText id={`checkbox-list-secondary-label-${"total"}`}><span style={{ fontWeight: 'bold', fontSize: 18, margin: 10 }}><Text>TOTAL</Text></span> </ListItemText>
                        </Grid>

                    </Grid>

                </ListItem>
                <Grid
                    container
                //spacing={2}
                >
                    {formInput.orderItems.length > 0 ? formInput.orderItems.map((item) =>

                        <OrderItem
                            key={item.product.productId}
                            orderItem={item}
                            order={formInput}
                            onRemove={onRemove}
                            onHandleSave={(inputs) => {
                                onHandleSave(inputs, item);
                            }}
                        />
                    )
                        : <div><Text>No product added</Text>.</div>}
                </Grid>

            </List>

            {/* <Divider /> */}
            <Grid
                container
                spacing={2}
            //sx={{marginTop: 2}}
            >
                <Grid item xs={12} sm={8} md={8}>
                    <div style={{ background: 'white', padding: '16px' }}>
                        <QRCode value={window.location.href} size={150} />
                    </div>

                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ border: 1 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Text>Subtotal</Text>:
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', paddingRight: 10 }}>
                            {priceFormat(formInput.subtotal)}
                        </Grid>

                        {formInput.shippingPrice > 0 && <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Text>Shipping Cost</Text>:
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', paddingRight: 10 }}>
                                {priceFormat(formInput.shippingPrice)}
                            </Grid></>
                        }

                        {formInput.taxIncludedTotal > 0 && <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Text>Tax</Text>:
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', paddingRight: 10 }}>
                                {priceFormat(formInput.taxIncludedTotal)}
                            </Grid></>
                        }
                        {/* <Grid item xs={12} sm={6} md={6}>
                            Discount:
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ color: formInput.discountTotal > 0 ? 'red' : null }}>
                            {formInput.discountTotal > 0 ? '-' + priceFormat(formInput.discountTotal) : priceFormat(formInput.discountTotal)}
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6} style={{ fontSize: 18 }}>
                            <b><Text>Grand Total</Text>: </b>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', paddingRight: 10 }}>
                            {priceFormat(formInput.grandTotal)}
                        </Grid>
                        {formInput.orderCredit > 0 && <>
                            <Grid style={{ color: 'red' }} item xs={12} sm={6} md={6} >
                                <b>  <Text>Amount Due</Text>:</b>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right', color: 'red', paddingRight: 10 }}>
                                {priceFormat(formInput.orderCredit)}
                            </Grid></>
                        }

                    </Grid>

                </Grid>

            </Grid>

            {/* <Divider style={{ maxWidth: '50%', marginLeft: '50%' }} /> */}

            {/* <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} sm={6} md={6}>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            Total Paid:
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            5,000,000.00 F
                        </Grid>
                    </Grid>

                </Grid>

            </Grid> */}
            {/* <Divider /> */}

        </div>
    );
}
