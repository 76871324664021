import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import ActionAreaCard from "../../components/cards/ActionAreaCard";
import LoadingDialog from "../../components/dialogs/Loading";

import "./Vendors.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Vendors() {
    //const [vendors, setVendors] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { store, actions, vendors, userPermissions } = useAppContext();
    //const { getVendor } = useAppContext();

    useEffect(() => {
//console.log('store.userPermissions', userPermissions);
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            try {
                //actions.onSetIsLoading(true);
                //actions.onGetVendors();
            } catch (e) {
                onError(e);
            }
            actions.onSetIsLoading(false);
            //setIsLoading(false);
        }
        onLoad();
    }, [isAuthenticated]);

    useEffect(() => {
        actions.onSetSelected('vendors');
    }, []);
    function renderVendorsList(vendors) {
        return (
            <>
                {(userPermissions.vendors_write == true || userPermissions.admin == true) &&
                    <LinkContainer to="/a_portal/vendors/form">
                        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                            <BsPencilSquare size={17} />
                            <span className="ml-2 font-weight-bold">Add New Vendor</span>
                        </ListGroup.Item>
                    </LinkContainer>
                }

                <Grid container item spacing={1}>

                    {vendors.map(({ vendorName, email, phoneNumber, profileImage, vendorAddress, vendorId }, index) => (
                        <LinkContainer to={`/a_portal/vendors/${vendorId}`} key={index}>
                            <Grid item xs={12} sm={6} md={4}>

                                <ActionAreaCard
                                    title={vendorName}
                                    attachment={profileImage}
                                    description={`${vendorAddress.city || ''}, ${vendorAddress.state || ''}`}
                                />

                            </Grid>
                        </LinkContainer>
                    ))}
                </Grid>
            </>
        );
    }

    return (
        <div className="Vendors">
            <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom">Vendors</h2>
                <ListGroup>{renderVendorsList(vendors)}</ListGroup>
            </Box>
        </div>
    );
}