import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

import ProductMultiFilesUpload from "../../../components/forms/ProductMultiFilesUpload";
import LoaderButton from "../../../components/LoaderButton";
import FeedBack from "../../../components/shared/FeedBack";
import ProductAttributesTable from "../../../components/tables/ProductAttributesTable";
import { onError } from "../../../lib/errorLib";
import config from "../../../config";
import { useAppContext } from "../../../lib/contextLib";
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../../services/products";
import {
    updateSetting
} from "../../../services/settings";
import { Text } from '../../../services/translator';
import CategorySelect from "../../../components/forms/CategorySelect";
import CategoryMultiSelect from "../../../components/forms/CategoryMultiSelect";
import RelatedProducts1 from "../../../components/forms/RelatedProducts";
import ProductTagsInput from "../../../components/forms/ProductTagsInput";
import Settings from "../../Settings";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function EmailSettingsForm() {
    const file = useRef(null);
    //const { id } = useParams();
    const history = useHistory();
    const [showSave, setShowSave] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        settings,
        user,
        vendor
    } = useAppContext();
    const [productTags, setProductTags] = useState([]);
    const [productAttributes, setProductAttributes] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);
    const [originalCategoryIds, setOriginalCategoryIds] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [orderConfirmationCopyTo, setOrderConfirmationCopyTo] = useState([]);
    const [formInput, setFormInput] = useState({
        orderConfirmationCopyTo: [],
        fromEmail: '',
        emailHost: '',
        emailPort: '',
        emailUsername: '',
        emailPassword: '',
    });

    const [errors, setErrors] = useState({
        orderConfirmationCopyTo: '',
        fromEmail: '',
        emailHost: '',
        emailPort: '',
        emailUsername: '',
        emailPassword: '',
    });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
    
        setFormInput({...settings});
        setOrderConfirmationCopyTo(settings.orderConfirmationCopyTo || []);

    }, [settings]);

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.fromEmail == '') {
            newErrors.fromEmail = 'From email is required.';
        } else {
            newErrors.fromEmail = '';
        }

        if (formInput.emailHost == '') {
            newErrors.emailHost = 'Host is required.';
        } else {
            newErrors.emailHost = '';
        }
        
        if (formInput.emailPort == '') {
            newErrors.emailPort = 'Port is required.';
        } else {
            newErrors.emailPort = '';
        }

        if (formInput.emailUsername == '') {
            newErrors.emailUsername = 'Username is required.';
        } else {
            newErrors.emailUsername = '';
        }

        if (formInput.emailPassword == '') {
            newErrors.emailPassword = 'Password is required.';
        } else {
            newErrors.emailPassword = '';
        }

        setErrors(newErrors);

        if (
            newErrors.fromEmail.length > 0 ||
            newErrors.emailHost.length > 0 ||
            newErrors.emailPort.length > 0 ||
            newErrors.emailUsername.length > 0 ||
            newErrors.emailPassword.length > 0 
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setIsLoading(true);
        setShowSave(true);
        updateErrors(name);

    }

    function handleCopyToChange(event, values) {

        setOrderConfirmationCopyTo(values);

    }

    async function handleSubmit() {
        
        let newFormInput = {
            ...formInput
        };

        let isValid = validateForm();
        setIsLoading(true);
        if (isValid) {
            let response = null;
            try {
                response = await updateSetting({ ...newFormInput, orderConfirmationCopyTo });

                actions.onSetFeedback(true, 'Email settings was successfully updated.', 'success');

                actions.onGetSettings();
                setIsLoading(false);
                setShowSave(false);
            } catch (e) {
                onError(e);
                setIsLoading(false);
            }
        }

    }

    return (
        <div className="EmailSettingsForm" >
            <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
                {/* <h2 className="pb-3 mb-3 border-bottom"> {'Product Create Form'}</h2> */}
                <Grid container spacing={3}>

                    <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.fromEmail !== '' ? true : false}
                            id="outlined-error"
                            name="fromEmail"
                            helperText={errors.fromEmail}
                            label={"From Email"}
                            value={formInput.fromEmail}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.emailHost !== '' ? true : false}
                            id="outlined-error"
                            name="emailHost"
                            helperText={errors.emailHost}
                            label="Email Host"
                            value={formInput.emailHost}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.emailPort !== '' ? true : false}
                            id="outlined-error"
                            name="emailPort"
                            type="number"
                            helperText={errors.emailPort}
                            label="Email Port"
                            value={formInput.emailPort}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>


                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.emailUsername !== '' ? true : false}
                            id="outlined-error"
                            name="emailUsername"
                            helperText={errors.emailUsername}
                            label="Username"
                            value={formInput.emailUsername}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.emailPassword !== '' ? true : false}
                            id="outlined-error"
                            name="emailPassword"
                            type="password"
                            helperText={errors.emailPassword}
                            label="Email Password"
                            value={formInput.emailPassword}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <Autocomplete
                            // key={new Date()}
                            filterSelectedOptions
                            multiple
                            id="tags-filled"
                            options={orderConfirmationCopyTo.map((option) => option)}
                            value={orderConfirmationCopyTo}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            onChange={handleCopyToChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    type={'email'}
                                    name="orderConfirmationCopyTo"
                                    label="Copy To Emails"
                                    placeholder="emails"
                                />
                            )}
                        />
                    </Grid>



                </Grid>
            </Box>

            {showSave && <LoaderButton
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    //marginLeft: 8
                }}
                block
                type="submit"
                size="sm"
                variant="primary"
                isLoading={isLoading}
                onClick={() => {
                    handleSubmit();
                }}
            >
                Save
            </LoaderButton>}

        </div>
    );
}
