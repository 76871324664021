import React, { useCallback, useEffect, useState } from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import Button from '@mui/material/Button';
import {
  cashierReportDataFormat,
  runReport
} from "../../services/reports";
import { useAppContext } from "../../lib/contextLib";

//cashierReportDataFormat

export default function SheetJSReactAoO(
  {
    reportData,
    cashierCounts,
    deposits,
    spendings
  }) {

  const {
    actions,
    userPermissions,
    vendors,
    spendingCategories,
    bankAccounts,
    users
  } = useAppContext();
  /* the component state is an array of presidents */
  const [pres, setPres] = useState([]);
  const [showDownload, setShowDownload] = useState(false);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      // const f = await (
      //   await fetch("https://sheetjs.com/pres.xlsx")
      // ).arrayBuffer();
      // const wb = read(f); // parse the array buffer
      // const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      // const data = utils.sheet_to_json(ws); // generate objects
      // console.log('original data', data);   
      //setPres(data); // update state
    })();

    //setPres(reportData); // update state
    if(vendors.length > 0 && spendingCategories.length > 0 && users.length > 0){
      setShowDownload(true);
      formatData(reportData, cashierCounts, deposits, spendings);
    }
    

  }, [
    reportData,
    cashierCounts,
    deposits,
    spendings,
    vendors,
    spendingCategories,
    bankAccounts,
    users
  ]);

  const formatData = async (data, cashierCountsData, depositsData, spendingsData) => {

    let response = await cashierReportDataFormat(
      data,
      cashierCountsData,
      depositsData,
      spendingsData,
      vendors,
      spendingCategories,
      bankAccounts,
      users
    );//add deposits in place of array

    setPres(response);

  }

  /* get state data and export to XLSX */
  const exportFile = useCallback(() => {
    let data_headers = [
      'Order Date',
      'Order Number',
      'Customer',
      'Grand Total',
      'Description',
      'Quantity',
      'Price',
      'Total',
      'Spending',
      'Crédits',
      'Vehicle',
      'Versement',
      'Loan For',
      'Loan Totals'
    ];

    const ws = utils.json_to_sheet(pres, { header: data_headers, origin: -1 });//Convert the json to Sheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    
    let wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ];

    ws['!cols'] = wscols;
    writeFileXLSX(wb, "SheetJSReactAoO.xlsx", { cellStyles: true });
  }, [pres]);

  const runExcelReport = async () => {

    let response = await runReport();//add deposits in place of array

  }

  return (
    <>
      <Button
        disabled={showDownload? false : true }
        sx={{
          marginTop: 1,
        }}
        variant="outlined"
        onClick={
          exportFile
        }>
        Export Data
      </Button>
    </>);
}