import React, { useState, useEffect, useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Box from '@mui/material/Box';
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import UsersList from "../../components/tables/UsersTable";

import "./Users.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};


export default function Users() {
    const { isAuthenticated } = useAppContext();
    const { store, actions, users } = useAppContext();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            try {
                // actions.onSetIsLoading(true);
                // actions.onGetUsers();
            } catch (e) {
                onError(e);
            }
            actions.onSetIsLoading(false);
        }
        onLoad();
    }, [isAuthenticated]);

    useEffect(() => {
        actions.onSetSelected('users');
    }, []);

    function renderUsersList() {
        return (
            <>
                <LinkContainer to="/a_portal/users/signup">
                    <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={17} />
                        <span className="ml-2 font-weight-bold">Add New User</span>
                    </ListGroup.Item>
                </LinkContainer>
                <UsersList usersData={users}/>
            </>
        );
    }

    return (
        <div className="users">
            <Box sx={{
                flexGrow: 1,
                marginTop: -5,
                //marginBottom: 2
            }}
                style={styles.whiteBox}
            >
                <h2 className="pb-3 mt-4 mb-3 border-bottom">Users</h2>
                <ListGroup>{renderUsersList()}</ListGroup>
            </Box>
        </div>
    );
}