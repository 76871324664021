import { API } from "aws-amplify";
import {
    //createSettings,
    updateSetting,
    getSetting
} from "./settings";
import {
    updateProduct,
    getProduct
} from "./products";

var moment = require('moment');
/**
* Function for getting all product dispatches 
* @param {function} getAllProductDispatches
*/
export const getAllProductDispatches = async () => {

    let response;

    try {

        response = await API.get("adminAPI", "/admin/products/dispatches");

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove dispatch
* @param {function} removeProductDispatch
*/
export const removeProductDispatch = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products/dispatches/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for getting a dispatch
* @param {function} getProductDispatch
*/
export const getProductDispatch = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/dispatches/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting vendor dispatches
* @param {function} getVendorDispatches
*/
export const getVendorDispatches = async (id) => {

    let response;
    try {

        response = await API.get("adminAPI", `/admin/products/vendors/dispatches/${id}`);
        
    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a dispatch
* @param {object} data  
* @param {function} saveDispatch 
*/
export const saveDispatch = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products/dispatches", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for saving  a dispatch
* @param {object} data  
* @param {function} saveDispatchHistoryVendor 
*/
export const saveDispatchHistoryVendor = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products/dispatches/history/vendor", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a dispatch
* @param {object} data  
* @param {function} saveDispatchHistoryProduct 
*/
export const saveDispatchHistoryProduct = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products/dispatches/history/product", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};




/**
* Function for updating a dispatch
* @param {function} updateDispatch 
*/
export const updateDispatch = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products/dispatches/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for getting dispatch history for products
* @param {function} getProductDispatchHistory
*/
export const getProductDispatchHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/dispatches/history/product/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting dispatch history for vendors
* @param {function} getVendorDispatchHistory
*/
export const getVendorDispatchHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/dispatches/history/vendor/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for getting pending dispatch for vendors
* @param {function} getVendorPendingDispatches
*/
export const getVendorPendingDispatches = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/vendors/pending/${id}`);

       // console.log('Pending Dispatch', response);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



